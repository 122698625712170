import { useEffect, useState } from 'react';
import './layout.scss';
import { useNavigate, useLocation } from 'react-router-dom';
import Sidebar from '@components/common/Sidebar';
import Language from '@components/common/Language';
import User from '@components/common/User';
import { useTranslation } from 'react-i18next';
import { Bars3Icon } from '@heroicons/react/20/solid';
import ExportModule from '@components/common/ExportModule';
import TopBanner from '@components/common/TopBanner';
import ApiService from '@services/ApiService';
import moment from 'moment';

const Desktop = (props: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [display, setDisplay] = useState(false);
  const [userInfo, setUserInfo] = useState(null as any);
  const [active, setActive] = useState(false);
  const [permissions, setPermissions] = useState(null as any);
  const { t } = useTranslation();
  const [showSideBar, setShowSideBar] = useState(true);
  const [banners, setBanners] = useState([] as any);

  useEffect(() => {
    const wsCurrency = localStorage.getItem('wsCurrency');

    if (wsCurrency && wsCurrency.toLowerCase() === 'usd') {
      const currentDate = moment().format('YYYY-MM-DD');

      sessionStorage.setItem('wsRatesDate', currentDate);
    }
  }, []);

  useEffect(() => {
    const usr = localStorage.getItem('usr');

    if (!usr) {
      if (location.pathname !== '/forgot-password' && location.pathname !== '/sign-in' && location.pathname !== '/reset-password') {
        navigate('/sign-in');
      }
    } else {
      if (location.pathname === '/forgot-password' || location.pathname === '/sign-in' || location.pathname === '/reset-password') {
        navigate('/');
      } else {
        setUserInfo(JSON.parse(usr));
        setDisplay(true);
      }
    }
  }, [navigate, location]);

  useEffect(() => {
    if (userInfo) {
      const roles = [] as any;

      userInfo.permissions.forEach((item: any) => {
        const obj = {
          id: (item.resource !== "") ? item.resource : item.app,
          permissions: item.action,
          parrent: item.app
        };

        roles.push(obj);

        if (location.pathname.indexOf(obj.id) !== -1) {
          setActive(true);

          if (location.pathname.indexOf('edit') !== -1) {
            if (!item.action.includes('edit')) {
              setActive(false);
            }
          } else if (location.pathname.indexOf('create') !== -1) {
            if (!item.action.includes('create')) {
              setActive(false);
            }
          }
        }

        setPermissions(roles)

      });

      if (location.pathname === '/change-password' || location.pathname.indexOf('/profile') !== -1) {
        setActive(true);
      }

      fetchTopBanner()
    }
  }, [userInfo, location]);

  const fetchTopBanner = async () => {
    try {
      const res = await ApiService.get('organization/banners/workspace');

      if (res?.data?.length > 0) {
        setBanners(res?.data);
      }
    } catch (error: any) {
      console.log(error?.message);
    }
  }

  return (
    <div id="desktop">
      {
        display ?
          <div>
            {
              banners.length > 0 &&
              <TopBanner banners={banners} />
            }

            <div className="flex w-full h-full">
              <div className={(permissions && showSideBar) ? "desktop-left shadow-md shadow-blue-gray-200" : "desktop-left-hidden"}>
                <Sidebar
                  permissions={permissions}
                />
              </div>

              <div className={(showSideBar) ? "desktop-right" : "desktop-right-full"}>
                <div className={`${(showSideBar) ? "header-fixed" : "header-fixed-full"}`}>
                  <div className="layout-menu">
                    <Bars3Icon className='w-6 h-6 hamburger-icon'
                      onClick={() => setShowSideBar(!showSideBar)}
                    />

                    <div className='layout-menu-right'>
                      <ExportModule
                        t={t} />

                      <Language />

                      {
                        userInfo &&
                        <User
                          userInfo={userInfo}
                        />
                      }
                    </div>

                  </div>
                </div>
                {
                  active ?
                    <div className="layout-content">{props.children}</div>
                    :
                    <div className="layout-content">
                      <div className="access-denied">
                        <div className="access-denied__label">{t('access-denied')}</div>
                        <div className="access-denied__content">{t('access-denied-content')}</div>
                        <div className="access-denied__content">{t('access-denied-content-2')}</div>
                      </div>
                    </div>
                }
              </div>
            </div>
          </div>

          :
          <>{props.children}</>
      }
    </div>
  )
}

export default Desktop;