import useWindowWidth from "@helpers/InitWidthDevice";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Select from 'react-select';
import DateRangePicker from "@components/common/DateRangePicker";
import { ArrowDownIcon, MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import {
  Typography,
  Popover,
  PopoverHandler,
  PopoverContent,
  Button
} from "@material-tailwind/react";
import OrdersUI from "UI/desktop/Orders";
import OrdersUIMobile from "UI/mobile/Orders";
import ApiService from "@services/ApiService";
import Message from "@components/common/Message";
import Loader from "@components/common/Loader";
import Pagination from "@components/common/Pagination";
import _, { get } from "lodash";
import moment from "moment";
import { EyeIcon } from "@heroicons/react/24/solid";
import { useNavigate, useSearchParams } from "react-router-dom";

const OrdersForm = (props: any) => {
  const { t, mobile, language } = props;
  const [openFilter, setOpenFilter] = useState(false);
  const [applyDate, setApplyDate] = useState({
    start: '',
    end: ''
  });
  const [purchasedDate, setPurchasedDate] = useState({
    start: '',
    end: ''
  })
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('success');
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [orderList, setOrderList] = useState([] as any);
  const [paging, setPaging] = useState(null as any);
  const [filter, setFilter] = useState(true);
  const [filterGroup, setFilterGroup] = useState({
    q: '',
    payment_method: '',
    order_status: '',
    order_type: '',
    event_id: '',
    is_vat: ''
  });
  const [eventList, setEventList] = useState([] as any);
  const [orderType, setOrderType] = useState([] as any);
  const [fetchData, setFetchData] = useState(false);
  const [accountType, setAccountType] = useState('all');
  const [openExportGroup, setOpenExportGroup] = useState(false);

  const order_status = [
    {
      value: '',
      label: t('all-status')
    },
    {
      value: 'doi_thanh_toan',
      label: t('order-pending')
    },
    {
      value: 'dat_hang_thanh_cong',
      label: t('order-success')
    },
    {
      value: 'hoan_tien',
      label: t('order-refund')
    },
    {
      value: 'da_huy',
      label: t('order-canceled')
    },
  ] as any;

  const payment_method = [
    {
      value: '',
      label: t('all')
    },
    {
      value: 'atm',
      label: t('atm')
    },
    {
      value: 'bank_transfer',
      label: t('bank-transfer')
    },
    {
      value: 'credit',
      label: t('credit')
    },
    {
      value: 'partner_vnpay',
      label: 'Partner VNPay'
    },
    {
      value: 'vn_pay_b',
      label: 'VNPAY'
    },
    {
      value: 'vnpay',
      label: 'QR Code'
    },
    {
      value: 'zalopay',
      label: 'ZaloPay'
    },
    {
      value: 'viet_qr',
      label: 'Viet QR'
    },
    {
      value: 'momo',
      label: t('momo')
    },
    {
      value: 'voucher',
      label: t('coupon')
    },
    {
      value: 'shipcode',
      label: 'Ship Code'
    },
    {
      value: 'free',
      label: t('free-ticket')
    },
    {
      value: 'bnpl',
      label: t('bnpl')
    },
    {
      value: 'viettel_money',
      label: t('viettel_money')
    },
    {
      value: 'v_atm',
      label: t('v_atm')
    },
    {
      value: 'v_credit',
      label: t('v_credit')
    },
    {
      value: 'payoo_atm',
      label: t('payoo_atm')
    },
    {
      value: 'payoo_credit',
      label: t('payoo_credit')
    },
    {
      value: 'onepay_viet_qr',
      label: t('onepay_viet_qr')
    },
  ] as any;

  const vat_option = [
    {
      value: '',
      label: t('all')
    },
    {
      value: "0",
      label: t('not')
    },
    {
      value: "1",
      label: t('yes')
    }
  ] as any;

  const TABLE_HEAD_ALL = [t('no'), t('order-code'), t('buyer-email'), t('buyer-phone'), t('ticket-name'), t('event-name'), t('group-name'), t('e-ticket'), t('VAT'), t('payment-method'), t('order-status'), t('order-created-at'), t('order-successful-at'), t('action')];
  const TABLE_HEAD_SPORT = [t('no'), t('order-code'), t('buyer-email'), t('buyer-phone'), t('ticket-name'), t('event-name'), t('group-name'), t('VAT'), t('payment-method'), t('order-status'), t('order-created-at'), t('order-successful-at'), t('action')];
  const TABLE_HEAD_ATTRACTION = [t('no'), t('order-code'), t('buyer-email'), t('buyer-phone'), t('ticket-name'), t('event-name'), t('e-ticket'), t('VAT'), t('payment-method'), t('order-status'), t('order-created-at'), t('order-successful-at'), t('action')];

  const [searchParams] = useSearchParams();
  const paramsObject = Object.fromEntries([...searchParams]);
  const navigate = useNavigate();
  const [initParams, setInitParams] = useState(true);
  const [eventDefault, setEventDefault] = useState(null as any);
  const [orderTypeDefault, setOrderTypeDefault] = useState(null as any);
  const [paymentDefault, setPaymentDefault] = useState(null as any);
  const [orderStatusDefault, setOrderStatusDefault] = useState(null as any);
  const [VATDefault, setVATDefault] = useState(null as any);

  useEffect(() => {
    if (initParams) {
      const keys = Object.keys(paramsObject);

      if (keys?.length > 0) {
        handleInitSearchValue();
      } else {
        setFilter(true);
        setInitParams(false);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initParams]);

  const handleInitSearchValue = () => {
    const filterTemp = _.cloneDeep(filterGroup);

    if (paramsObject.q && paramsObject.q !== '') {
      filterTemp.q = paramsObject.q;
    }

    if (paramsObject.event_id && paramsObject.event_id !== '') {
      filterTemp.event_id = paramsObject.event_id;
    }

    if (paramsObject.order_type && paramsObject.order_type !== '') {
      filterTemp.order_type = paramsObject.order_type;
    }

    if (paramsObject.payment_method && paramsObject.payment_method !== '') {
      filterTemp.payment_method = paramsObject.payment_method;
    }

    if (paramsObject.order_status && paramsObject.order_status !== '') {
      filterTemp.order_status = paramsObject.order_status;
    }

    if (paramsObject.is_vat && paramsObject.is_vat !== '') {
      filterTemp.is_vat = paramsObject.is_vat;
    }

    if (paramsObject.gte && paramsObject.gte !== '') {
      setApplyDate({
        start: paramsObject.gte,
        end: paramsObject.lte
      })
    }

    if (paramsObject.sc_from && paramsObject.sc_from !== '') {
      setPurchasedDate({
        start: paramsObject.sc_from,
        end: paramsObject.sc_to
      })
    }

    setFilterGroup(filterTemp);

    const intervalInit = setInterval(() => {
      setFilter(true);
      setInitParams(false);
      clearInterval(intervalInit);
    }, 1000);

  }

  useEffect(() => {
    handleUpdateRoute();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterGroup, applyDate, purchasedDate])

  const handleUpdateRoute = () => {
    const newSearchParams = new URLSearchParams(searchParams);

    newSearchParams.set('q', filterGroup.q);
    newSearchParams.set('event_id', filterGroup.event_id);
    newSearchParams.set('order_type', filterGroup.order_type);
    newSearchParams.set('payment_method', filterGroup.payment_method);
    newSearchParams.set('order_status', filterGroup.order_status);
    newSearchParams.set('is_vat', filterGroup.is_vat);
    newSearchParams.set('gte', applyDate.start);
    newSearchParams.set('lte', applyDate.end);
    newSearchParams.set('sc_from', purchasedDate.start);
    newSearchParams.set('sc_to', purchasedDate.end);

    const newParamsObject = Object.fromEntries([...newSearchParams]);

    const keys = Object.keys(newParamsObject);
    let changeRoute = false;

    if (keys?.length > 0) {
      keys?.forEach((key: string) => {
        if (newParamsObject[key] !== '') {
          changeRoute = true;
        }
      });
    }

    if (changeRoute || !initParams) {
      navigate(`?${newSearchParams.toString()}`, { replace: true });
    }
  }

  useEffect(() => {
    if (eventList?.length > 0) {
      if (filterGroup.event_id !== '') {
        eventList.forEach((item: any) => {
          if (item.value === filterGroup.event_id) {
            setEventDefault(item);
          }
        })
      } else {
        setEventDefault(eventList[0]);
      }
    }
  }, [filterGroup, eventList]);

  useEffect(() => {
    if (orderType?.length > 0) {
      if (filterGroup.order_type !== '') {
        orderType.forEach((item: any) => {
          if (item.value === filterGroup.order_type) {
            setOrderTypeDefault(item);
          }
        })
      } else {
        setOrderTypeDefault(orderType[0]);
      }
    }
  }, [filterGroup, orderType]);

  useEffect(() => {
    if (!initParams) {
      if (filterGroup.payment_method !== '') {
        payment_method.forEach((item: any) => {
          if (item.value === filterGroup.payment_method) {
            setPaymentDefault(item);
          }
        })
      } else {
        setPaymentDefault(payment_method[0]);
      }

      if (filterGroup.order_status !== '') {
        order_status.forEach((item: any) => {
          if (item.value === filterGroup.order_status) {
            setOrderStatusDefault(item);
          }
        })
      } else {
        setOrderStatusDefault(order_status[0])
      }

      if (filterGroup.is_vat !== '') {
        vat_option.forEach((item: any) => {
          if (item.value === filterGroup.is_vat) {
            setVATDefault(item);
          }
        })
      } else {
        setVATDefault(vat_option[0])
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterGroup, initParams]);

  useEffect(() => {
    const usr = localStorage.getItem('usr');

    if (usr) {
      const user_info = JSON.parse(usr);

      if (user_info.event_type && user_info.event_type.length > 0) {
        if (user_info.event_type.includes('sport')) {
          if (user_info.event_type.includes('attraction')) {
            setOrderType([
              {
                value: '',
                label: t('all-order-type')
              },
              {
                value: 'virtual_event',
                label: t('individual-ticket')
              },
              {
                value: 'group_buy',
                label: t('group-ticket')
              },
              {
                value: 'attraction',
                label: t('attraction')
              }
            ]);

            setAccountType("all");
          } else {
            setOrderType([
              {
                value: '',
                label: t('all-order-type')
              },
              {
                value: 'virtual_event',
                label: t('individual-ticket')
              },
              {
                value: 'group_buy',
                label: t('group-ticket')
              }
            ]);

            setAccountType("sport");
          }
        } else {
          setAccountType('attraction');
        }
      }

      setFetchData(true);
    }
  }, [t]);

  useEffect(() => {
    if (fetchData) {
      fetchEventList();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchData]);

  const fetchEventList = async () => {
    setLoading(true);
    try {
      const result = await ApiService.get(`events`);

      if (result) {
        const arr = [
          {
            value: '',
            label: t('all')
          }
        ] as any;

        result.forEach((item: any) => {
          if (item.id !== 'all') {
            const obj = {
              value: item.id,
              label: get(item, `name_${language}`, '')
            };

            arr.push(obj);
          }

        });

        setEventList(arr);

        if (page === 0) {
          setPage(1);
        } else {
          setLoading(false);
        }
      }
    } catch (error: any) {
      setMessage(error.message);
      setMessageType('error');
      setLoading(false);
    }
  }

  useEffect(() => {
    if (page > 0 && filter) {
      fetchOrderList();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, filter]);

  const fetchOrderList = async () => {
    setLoading(true);
    try {
      const result = await ApiService.get(`orders/page/${page}?q=${filterGroup.q}&gte=${applyDate.start}&lte=${applyDate.end}&sc_from=${purchasedDate.start}&sc_to=${purchasedDate.end}&payment_method=${filterGroup.payment_method}&order_status=${filterGroup.order_status}&order_type=${filterGroup.order_type}&event_id=${filterGroup.event_id}&is_vat=${filterGroup.is_vat}&limit=10`);

      if (result && result.orders) {
        setOrderList(result.orders);
        setPaging(result.paging);
        setLoading(false);
        setFilter(false);
      }
    } catch (error: any) {
      setMessage(error.message);
      setMessageType('error');
      setLoading(false);
    }
  }

  const handleExportOrder = async (used_code: number) => {
    setLoading(true);

    const wsCurrency = localStorage.getItem('wsCurrency');

    const currentCurrency = (wsCurrency && wsCurrency !== '') ? wsCurrency : 'vnd';

    try {
      const result = await ApiService.get(`exports/order?q=${filterGroup.q}&gte=${applyDate.start}&lte=${applyDate.end}&sc_from=${purchasedDate.start}&sc_to=${purchasedDate.end}&payment_method=${filterGroup.payment_method}&order_status=${filterGroup.order_status}&order_type=${filterGroup.order_type}&event_id=${filterGroup.event_id}&is_vat=${filterGroup.is_vat}&is_used_code=${used_code}&currency=${currentCurrency}`);

      if (result) {
        setMessage(t('export-success'));
        setMessageType('success');
      }
    } catch (error: any) {
      setMessage(error.message);
      setMessageType('error');
    }

    setLoading(false);

  }

  const handleUpdateFilter = (value: string, key: string) => {
    const dataFilter = { ...filterGroup } as any;

    dataFilter[key] = value;
    setFilterGroup({ ...dataFilter });
  }

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      setFilter(true);
    }
  };

  const getValueSelect = (data: any, key: any) => {
    const dataFilter = { ...filterGroup } as any;
    const value = dataFilter[key];

    let res;
    data.forEach((item: any) => {
      if (item.value === value) {
        res = item;
      }
    });

    return res;
  }

  const getPaymentMethod = (method: string) => {
    const payment_method = method.toLowerCase();
    switch (payment_method) {
      case 'atm':
        return t('atm');
      case 'bank_transfer':
        return t('bank-transfer');
      case 'credit':
        return t('credit');
      case 'partner_vnpay':
        return 'Partner VNPay';
      case 'vn_pay_b':
        return 'VNPAY';
      case 'vnpay':
        return 'QR Code';
      case 'zalopay':
        return 'ZaloPay';
      case 'viet_qr':
        return 'Viet QR';
      case 'momo':
        return t('momo');
      case 'voucher':
        return t('coupon');
      case 'shipcode':
        return 'Ship Code';
      case 'free':
        return t('free-ticket');
      case 'bnpl':
        return t('bnpl');
      case 'viettel_money':
        return t('viettel_money');
      case 'v_atm':
        return t('v_atm');
      case 'v_credit':
        return t('v_credit');
      case 'payoo_atm':
        return t('payoo_atm');
      case 'payoo_credit':
        return t('payoo_credit');
      case 'onepay_viet_qr':
        return t('onepay_viet_qr');
      default:
        return method;
    }
  }

  if (!mobile) {
    return (
      <div>
        <div className="order-form__header">
          <div className="header-title">{t('order-management')}</div>

          <div className="flex items-center justify-between">
            <div className="w-[170px] h-[48px] rounded-[4px] border-[1px] border-[#A6A1FD] flex items-center justify-center cursor-pointer text-[#081774] btn-export-group mb-[10px] mr-[20px]" onClick={() => handleExportOrder(1)}>
              <ArrowDownIcon className="w-[16px] h-[16px] mr-[5px]" />

              <div className="text-[14px]">{t('export-used-code')}</div>
            </div>

            <div className="w-[121px] h-[48px] rounded-[4px] border-[1px] border-[#081774] flex items-center justify-center cursor-pointer text-[#fff] btn-export-group mb-[10px] bg-[#081774]" onClick={() => handleExportOrder(0)}>
              <ArrowDownIcon className="w-[16px] h-[16px] mr-[5px]" />

              <div className="text-[14px]">{t('export-order')}</div>
            </div>
          </div>

        </div>

        <div className="coupon-form" onKeyDown={handleKeyDown}>
          <div className="coupon-form__filter">
            <div className="filter-item search">
              <div className="filter-item__label">{t('search')}</div>

              <input className="filter-item__input"
                placeholder={t('placeholder-search-orders')}
                value={filterGroup.q}
                onChange={(e: any) => handleUpdateFilter(e.target.value, 'q')} />
            </div>

            {
              eventList.length > 0 &&
              <div className="filter-item events">
                <div className="filter-item__label">{t('event-name')}</div>

                {
                  eventDefault &&
                  <Select
                    defaultValue={eventDefault}
                    name="events"
                    options={eventList}
                    className="filter-item__select"
                    classNamePrefix="select"
                    placeholder={eventList[0].label}
                    onChange={(e: any) => handleUpdateFilter(e.value, 'event_id')} />
                }

              </div>
            }

            {
              orderType.length > 0 &&
              <div className="filter-item order-type">
                <div className="filter-item__label">{t('order-type')}</div>

                {
                  orderTypeDefault &&
                  <Select
                    defaultValue={orderTypeDefault}
                    name="order-type"
                    options={orderType}
                    className="filter-item__select"
                    classNamePrefix="select"
                    placeholder={orderType[0].label}
                    onChange={(e: any) => handleUpdateFilter(e.value, 'order_type')} />
                }

              </div>
            }

            <div className="filter-item order-type">
              <div className="filter-item__label">{t('payment-method')}</div>

              {
                paymentDefault &&
                <Select
                  defaultValue={paymentDefault}
                  name="payment-method"
                  options={payment_method}
                  className="filter-item__select"
                  classNamePrefix="select"
                  placeholder={payment_method[0].label}
                  onChange={(e: any) => handleUpdateFilter(e.value, 'payment_method')} />
              }

            </div>

            <div className="filter-item order-type">
              <div className="filter-item__label">{t('order-status')}</div>

              {
                orderStatusDefault &&
                <Select
                  defaultValue={orderStatusDefault}
                  name="order-status"
                  options={order_status}
                  className="filter-item__select"
                  classNamePrefix="select"
                  placeholder={order_status[0].label}
                  onChange={(e: any) => handleUpdateFilter(e.value, 'order_status')} />
              }

            </div>

            <div className="filter-item order-type VAT">
              <div className="filter-item__label">VAT</div>

              {
                VATDefault &&
                <Select
                  defaultValue={VATDefault}
                  name="VAT"
                  options={vat_option}
                  className="filter-item__select"
                  classNamePrefix="select"
                  placeholder={vat_option[0].label}
                  onChange={(e: any) => handleUpdateFilter(e.value, 'is_vat')} />
              }

            </div>

            <div className="filter-item apply-date">
              <div className="filter-item__label">{t('order-created-at')}</div>

              {
                !initParams &&
                <DateRangePicker
                  quickSelectDate={true}
                  mobile={mobile}
                  applyDate={applyDate}
                  setApplyDate={setApplyDate}
                />
              }

            </div>

            <div className="filter-item apply-date">
              <div className="filter-item__label">{t('order-successful-at')}</div>

              {
                !initParams &&
                <DateRangePicker
                  quickSelectDate={true}
                  mobile={mobile}
                  applyDate={purchasedDate}
                  setApplyDate={setPurchasedDate}
                />
              }

            </div>

            <Button
              placeholder="Button search coupon"
              className="btn-search"
              onPointerEnterCapture="none" onPointerLeaveCapture="none"
              onClick={() => {
                setFilter(true);
                setPage(1);
              }}>
              <MagnifyingGlassIcon className="w-4 h-4 icon-search" />
              <span>{t('search')}</span>
            </Button>
          </div>

          <div className="coupon-form__board">
            <div className="h-full w-full overflow-scroll overflow-y-hidden">
              <table className="w-full min-w-max table-auto text-left">
                <thead>
                  {
                    accountType === 'all' &&
                    <tr>
                      {TABLE_HEAD_ALL.map((head) => (
                        <th
                          key={head}
                          className="p-4"
                        >
                          <Typography
                            variant="small"
                            className="table-label"
                            placeholder="Typography"
                            onPointerEnterCapture="none" onPointerLeaveCapture="none"
                          >
                            {head}
                          </Typography>
                        </th>
                      ))}
                    </tr>
                  }

                  {
                    accountType === 'sport' &&
                    <tr>
                      {TABLE_HEAD_SPORT.map((head) => (
                        <th
                          key={head}
                          className="p-4"
                        >
                          <Typography
                            variant="small"
                            className="table-label"
                            placeholder="Typography"
                            onPointerEnterCapture="none" onPointerLeaveCapture="none"
                          >
                            {head}
                          </Typography>
                        </th>
                      ))}
                    </tr>
                  }

                  {
                    accountType === 'attraction' &&
                    <tr>
                      {TABLE_HEAD_ATTRACTION.map((head) => (
                        <th
                          key={head}
                          className="p-4"
                        >
                          <Typography
                            variant="small"
                            className="table-label"
                            placeholder="Typography"
                            onPointerEnterCapture="none" onPointerLeaveCapture="none"
                          >
                            {head}
                          </Typography>
                        </th>
                      ))}
                    </tr>
                  }

                </thead>
                <tbody>
                  {
                    orderList.length > 0 &&
                    orderList.map((item: any, index: number) => {
                      const isLast = index === orderList.length - 1;
                      const classes = isLast ? "p-4" : "p-4 border-b border-blue-gray-50";

                      return (
                        <tr key={`event_${item.id}_${index}`} className="table-row">
                          <td className={classes}>
                            <Typography
                              variant="small"
                              placeholder="Typography"
                              onPointerEnterCapture="none" onPointerLeaveCapture="none"
                            >
                              {
                                index < 9 &&
                                <span>{paging.current_page - 1}{index + 1}</span>
                              }

                              {
                                index === 9 &&
                                <span>{paging.current_page}0</span>
                              }
                            </Typography>
                          </td>

                          <td className={classes}>
                            <a href={`/orders/${item.code}/detail`}>
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-normal order-code"
                                placeholder="Typography"
                                onPointerEnterCapture="none" onPointerLeaveCapture="none"
                              >
                                {item.code}
                              </Typography>
                            </a>
                          </td>

                          <td className={classes}>
                            <Typography
                              onPointerEnterCapture="none" onPointerLeaveCapture="none"
                              variant="small"
                              placeholder="Typography"
                            >
                              {item.buyer_email}
                            </Typography>
                          </td>

                          <td className={classes}>
                            <Typography
                              onPointerEnterCapture="none" onPointerLeaveCapture="none"
                              variant="small"
                              placeholder="Typography"
                            >
                              {item.buyer_phone}
                            </Typography>
                          </td>

                          <td className={classes}>
                            <Typography
                              onPointerEnterCapture="none" onPointerLeaveCapture="none"
                              variant="small"
                              placeholder="Typography"
                            >
                              {get(item, `item_description_${language}`, '')}
                            </Typography>
                          </td>

                          <td className={classes}>
                            <Typography
                              onPointerEnterCapture="none" onPointerLeaveCapture="none"
                              variant="small"
                              placeholder="Typography"
                              className="w-[250px]"
                            >
                              {get(item, `event_name_${language}`, '')}
                            </Typography>
                          </td>

                          {
                            accountType !== 'attraction' &&
                            <td className={classes}>
                              <Typography
                                onPointerEnterCapture="none" onPointerLeaveCapture="none"
                                variant="small"
                                placeholder="Typography"
                              >
                                {item.group_name}
                              </Typography>
                            </td>
                          }

                          {
                            accountType !== 'sport' &&
                            <td className={classes}>
                              <Typography
                                onPointerEnterCapture="none" onPointerLeaveCapture="none"
                                variant="small"
                                placeholder="Typography"
                              >
                                {(item.ticket_use_for_date && item.ticket_use_for_date !== '') ? moment(item.ticket_use_for_date).format('DD/MM/YYYY HH:mm:ss') : 'N/A'}
                              </Typography>
                            </td>
                          }

                          <td className={classes}>
                            <Typography
                              onPointerEnterCapture="none" onPointerLeaveCapture="none"
                              variant="small"
                              placeholder="Typography"
                            >
                              <div className="coupon-item__row__value">
                                {
                                  item.is_vat === 0 ?
                                    <span>{t('not')}</span>
                                    :
                                    <span>{t('yes')}</span>
                                }
                              </div>
                            </Typography>
                          </td>

                          <td className={classes}>
                            <Typography
                              onPointerEnterCapture="none" onPointerLeaveCapture="none"
                              variant="small"
                              placeholder="Typography"
                            >
                              {getPaymentMethod(item.payment_method)}
                            </Typography>
                          </td>

                          <td className={classes}>
                            {
                              item.order_status === 'doi_thanh_toan' &&
                              <div className="table-body__status pending">{t('order-pending')}</div>
                            }

                            {
                              item.order_status === 'dat_hang_thanh_cong' &&
                              <div className="table-body__status success">{t('order-success')}</div>
                            }

                            {
                              item.order_status === 'da_huy' &&
                              <div className="table-body__status canceled">{t('order-canceled')}</div>
                            }

                            {
                              item.order_status === 'hoan_tien' &&
                              <div className="table-body__status refund">{t('order-refund')}</div>
                            }
                          </td>

                          <td className={classes}>
                            <Typography
                              onPointerEnterCapture="none" onPointerLeaveCapture="none"
                              variant="small"
                              placeholder="Typography"
                            >
                              {(item.created_at && item.created_at !== '') ? moment(item.created_at).format('DD/MM/YYYY HH:mm:ss') : 'N/A'}
                            </Typography>
                          </td>

                          <td className={classes}>
                            <Typography
                              onPointerEnterCapture="none" onPointerLeaveCapture="none"
                              variant="small"
                              placeholder="Typography"
                            >
                              {(item.purchased_at && item.purchased_at !== '') ? moment(item.purchased_at).format('DD/MM/YYYY HH:mm:ss') : 'N/A'}
                            </Typography>
                          </td>

                          <td className={classes}>
                            {
                              item.order_status === 'dat_hang_thanh_cong' &&
                              <a href={`/orders/${item.code}/edit`}>
                                <div className="w-[32px] h-[32px] rounded-[4px] bg-[#F5F6F7] flex items-center justify-center cursor-pointer">
                                  <img alt="icon-edit" src="/images/icons/icon-edit.svg" />
                                </div>
                              </a>
                            }
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>

            {
              paging &&
              <div className="board__bottom">
                {
                  paging.total_page > 1 &&
                  <Pagination
                    setPage={setPage}
                    paging={paging}
                    setFilter={setFilter}
                  />
                }
              </div>
            }
          </div>

          {
            message !== '' &&
            <Message
              message={message}
              messageType={messageType}
              setMessage={setMessage}
            />
          }

          {
            loading &&
            <Loader />
          }
        </div>
      </div>
    )
  } else {
    return (
      <div className="coupon-form" onKeyDown={handleKeyDown}>
        <div className="coupon-form__filter">
          <div className="filter__header">
            <div className="filter__header__label">{t('order-management')}</div>

            <div className="flex items-center">
              <Popover placement="bottom" open={openFilter} handler={setOpenFilter}>
                <PopoverHandler>
                  <MagnifyingGlassIcon className="w-5 h-5" />
                </PopoverHandler>
                <PopoverContent onPointerEnterCapture="none" onPointerLeaveCapture="none"
                  className="w-96 popup-search-coupon" placeholder="PopoverContent">
                  <div className="filter-item">
                    <div className="filter-item__label">{t('search')}</div>

                    <input type="text" className="filter-item__input"
                      placeholder={t('placeholder-search-orders-mobile')}
                      onChange={(e: any) => handleUpdateFilter(e.target.value, 'q')}
                      value={filterGroup.q} />
                  </div>
                  {
                    eventList.length > 0 &&
                    <div className="filter-item events">
                      <div className="filter-item__label">{t('event-name')}</div>

                      <Select
                        defaultValue={getValueSelect(eventList, 'event_id')}
                        name="events"
                        options={eventList}
                        className="filter-item__select"
                        classNamePrefix="select"
                        placeholder={eventList[0].label}
                        onChange={(e: any) => handleUpdateFilter(e.value, 'event_id')} />
                    </div>
                  }

                  {
                    orderType.length > 0 &&
                    <div className="filter-item order-type">
                      <div className="filter-item__label">{t('order-type')}</div>

                      <Select
                        defaultValue={getValueSelect(orderType, 'order_type')}
                        name="order-type"
                        options={orderType}
                        className="filter-item__select"
                        classNamePrefix="select"
                        placeholder={orderType[0].label}
                        onChange={(e: any) => handleUpdateFilter(e.value, 'order_type')} />
                    </div>
                  }

                  <div className="filter-item order-type">
                    <div className="filter-item__label">{t('payment-method')}</div>

                    <Select
                      defaultValue={getValueSelect(payment_method, 'payment_method')}
                      name="payment-method"
                      options={payment_method}
                      className="filter-item__select"
                      classNamePrefix="select"
                      placeholder={payment_method[0].label}
                      onChange={(e: any) => handleUpdateFilter(e.value, 'payment_method')} />
                  </div>

                  <div className="filter-item order-type">
                    <div className="filter-item__label">{t('order-status')}</div>

                    <Select
                      defaultValue={getValueSelect(order_status, 'order_status')}
                      name="order-status"
                      options={order_status}
                      className="filter-item__select"
                      classNamePrefix="select"
                      placeholder={order_status[0].label}
                      onChange={(e: any) => handleUpdateFilter(e.value, 'order_status')} />
                  </div>

                  <div className="filter-item order-type VAT">
                    <div className="filter-item__label">VAT</div>

                    <Select
                      defaultValue={getValueSelect(vat_option, 'is_vat')}
                      name="VAT"
                      options={vat_option}
                      className="filter-item__select"
                      classNamePrefix="select"
                      placeholder={vat_option[0].label}
                      onChange={(e: any) => handleUpdateFilter(e.value, 'is_vat')} />
                  </div>

                  <div className="filter-item">
                    <div className="filter-item__label">{t('order-created-at')}</div>

                    <DateRangePicker
                      quickSelectDate={true}
                      mobile={mobile}
                      setApplyDate={setApplyDate}
                      applyDate={applyDate}
                    />
                  </div>

                  <div className="filter-item">
                    <div className="filter-item__label">{t('order-successful-at')}</div>

                    <DateRangePicker
                      quickSelectDate={true}
                      mobile={mobile}
                      setApplyDate={setPurchasedDate}
                      applyDate={purchasedDate}
                    />
                  </div>

                  <Button
                    placeholder="Button search coupon"
                    className="btn-search"
                    onPointerEnterCapture="none" onPointerLeaveCapture="none"
                    onClick={() => {
                      setFilter(true);
                      setOpenFilter(false);
                      setPage(1);
                    }}>
                    <MagnifyingGlassIcon className="w-4 h-4 icon-search" />
                    <span>{t('search')}</span>
                  </Button>

                  <div className="w-[100%] h-[38px] rounded-[4px] border-[1px] border-[#A6A1FD] flex items-center justify-center cursor-pointer text-[#081774] btn-export-group mt-[10px]" onClick={() => handleExportOrder(0)}>
                    <ArrowDownIcon className="w-[16px] h-[16px] mr-[5px]" />

                    <div className="text-[14px]">{t('export-report')}</div>
                  </div>
                </PopoverContent>
              </Popover>

              <Popover placement="bottom" open={openExportGroup} handler={setOpenExportGroup}>
                <PopoverHandler>
                  <img src="/images/icons/icon-dots.svg" className="cursor-pointer ml-[10px]" alt="icon-export-group" />
                </PopoverHandler>

                <PopoverContent onPointerEnterCapture="none" onPointerLeaveCapture="none"
                  className="w-96 popup-search-coupon" placeholder="PopoverContent">
                  <div className="w-[100%] h-[48px] rounded-[4px] border-[1px] border-[#A6A1FD] flex items-center justify-center cursor-pointer text-[#081774] btn-export-group mb-[10px]" onClick={() => handleExportOrder(1)}>
                    <ArrowDownIcon className="w-[16px] h-[16px] mr-[5px]" />

                    <div className="text-[14px]">{t('export-used-code')}</div>
                  </div>

                  <div className="w-[100%] h-[48px] rounded-[4px] border-[1px] border-[#081774] flex items-center justify-center cursor-pointer text-[#fff] btn-export-group mt-[10px] bg-[#081774]" onClick={() => handleExportOrder(1)}>
                    <ArrowDownIcon className="w-[16px] h-[16px] mr-[5px]" />

                    <div className="text-[14px]">{t('export-order')}</div>
                  </div>


                </PopoverContent>
              </Popover>
            </div>

          </div>

        </div>

        <div className={openFilter ? "coupon-form__body overlay" : "coupon-form__body"}>
          {
            orderList.map((item: any, index: number) => {
              return (
                <div className="coupon-item" key={`coupon-item-${index}`}>
                  <div className="coupon-item__row">
                    <div className="coupon-item__row__label">{t('action')}</div>

                    <div className="flex items-center w-[100%] justify-end">
                      <div className="w-[32px] h-[32px] flex items-center justify-center bg-[#F5F6F7] rounded-[4px]" onClick={() => {
                        window.location.href = `/orders/${item.code}/detail`
                      }}>
                        <EyeIcon className="w-[20px] h-[20px] text-[#262626]" />
                      </div>

                      {
                        item.order_status === 'dat_hang_thanh_cong' &&
                        <div className="w-[32px] h-[32px] flex items-center justify-center bg-[#F5F6F7] rounded-[4px] ml-[12px]" onClick={() => {
                          window.location.href = `/orders/${item.code}/edit`
                        }}>
                          <img src="/images/icons/icon-edit.svg" alt="icon-edit" />
                        </div>
                      }

                    </div>
                  </div>

                  <div className="coupon-item__row">
                    <div className="coupon-item__row__label">{t('status')}</div>

                    {
                      (item.order_status === 'doi_thanh_toan') &&
                      <div className="coupon-item__row_value status pending">{t('order-pending')}</div>
                    }

                    {
                      (item.order_status === 'dat_hang_thanh_cong') &&
                      <div className="coupon-item__row_value status success">{t('order-success')}</div>
                    }

                    {
                      (item.order_status === 'da_huy') &&
                      <div className="coupon-item__row_value status canceled">{t('order-canceled')}</div>
                    }

                    {
                      (item.order_status === 'hoan_tien') &&
                      <div className="coupon-item__row_value status refund">{t('order-refund')}</div>
                    }
                  </div>

                  <div className="coupon-item__row">
                    <div className="coupon-item__row__label">{t('no')}</div>
                    <div className="coupon-item__row__value">{
                      index < 9 &&
                      <span>{paging.current_page - 1}{index + 1}</span>
                    }

                      {
                        index === 9 &&
                        <span>{paging.current_page}0</span>
                      }</div>
                  </div>

                  <div className="coupon-item__row">
                    <div className="coupon-item__row__label">{t('order-code')}</div>
                    <div className="coupon-item__row__value">{item.code}</div>
                  </div>

                  <div className="coupon-item__row">
                    <div className="coupon-item__row__label">{t('buyer-email')}</div>
                    <div className="coupon-item__row__value">{item.buyer_email}</div>
                  </div>

                  <div className="coupon-item__row">
                    <div className="coupon-item__row__label">{t('buyer-phone')}</div>
                    <div className="coupon-item__row__value">{item.buyer_phone}</div>
                  </div>

                  <div className="coupon-item__row">
                    <div className="coupon-item__row__label">{t('ticket-name')}</div>
                    <div className="coupon-item__row__value">{get(item, `item_description_${language}`, '')}</div>
                  </div>

                  <div className="coupon-item__row">
                    <div className="coupon-item__row__label">{t('event-name')}</div>
                    <div className="coupon-item__row__value">{get(item, `event_name_${language}`, '')}</div>
                  </div>

                  {
                    accountType !== 'attraction' &&
                    <div className="coupon-item__row">
                      <div className="coupon-item__row__label">{t('group-name')}</div>
                      <div className="coupon-item__row__value">{item.group_name}</div>
                    </div>
                  }

                  {
                    accountType !== 'sport' &&
                    <div className="coupon-item__row">
                      <div className="coupon-item__row__label">{t('e-ticket')}</div>
                      <div className="coupon-item__row__value">
                        {(item.ticket_use_for_date && item.ticket_use_for_date !== '') ? moment(item.ticket_use_for_date).format('DD/MM/YYYY HH:mm:ss') : 'N/A'}
                      </div>
                    </div>
                  }

                  <div className="coupon-item__row">
                    <div className="coupon-item__row__label">{t('VAT')}</div>
                    <div className="coupon-item__row__value">
                      {
                        item.is_vat === 0 ?
                          <span>{t('not')}</span>
                          :
                          <span>{t('yes')}</span>
                      }
                    </div>
                  </div>

                  <div className="coupon-item__row">
                    <div className="coupon-item__row__label">{t('payment-method')}</div>
                    <div className="coupon-item__row__value">{getPaymentMethod(item.payment_method)}</div>
                  </div>

                  <div className="coupon-item__row">
                    <div className="coupon-item__row__label">{t('order-created-at')}</div>
                    <div className="coupon-item__row__value">
                      {(item.created_at && item.created_at !== '') ? moment(item.created_at).format('DD/MM/YYYY HH:mm:ss') : 'N/A'}
                    </div>
                  </div>

                  <div className="coupon-item__row">
                    <div className="coupon-item__row__label">{t('order-successful-at')}</div>
                    <div className="coupon-item__row__value">
                      {(item.purchased_at && item.purchased_at !== '') ? moment(item.purchased_at).format('DD/MM/YYYY HH:mm:ss') : 'N/A'}
                    </div>
                  </div>
                </div>
              )
            })
          }

          {
            paging &&
            <div className="board__bottom">
              {
                paging.total_page > 1 &&
                <Pagination
                  setPage={setPage}
                  paging={paging}
                  setFilter={setFilter}
                />
              }
            </div>
          }
        </div>

        {
          message !== '' &&
          <Message
            message={message}
            messageType={messageType}
            setMessage={setMessage}
          />
        }

        {
          loading &&
          <Loader />
        }
      </div>
    )
  }

}

const Orders = () => {
  const { t, i18n: { language } = {} } = useTranslation();
  const windowWidth = useWindowWidth();

  if (windowWidth && windowWidth > 768) {
    return (
      <OrdersUI
        t={t}
        OrdersForm={OrdersForm}
        language={language}
      />
    )
  } else {
    return (
      <OrdersUIMobile
        t={t}
        OrdersForm={OrdersForm}
        language={language}
      />
    )
  }
}

export default Orders;