import useWindowWidth from "@helpers/InitWidthDevice";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CouponUI from "UI/desktop/Coupon";
import CouponUIMobile from "UI/mobile/Coupon";
import Select from 'react-select';
import { Button } from "@material-tailwind/react";
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import {
  Typography,
  Popover,
  PopoverHandler,
  PopoverContent,
} from "@material-tailwind/react";
import ApiService from "@services/ApiService";
import Message from "@components/common/Message";
import Loader from "@components/common/Loader";
import moment from 'moment';
import Pagination from "@components/common/Pagination";
import { get } from "lodash";
import { useNavigate, useSearchParams } from "react-router-dom";
import ConvertCurrency from "@helpers/convertCurrency";

const CouponForm = (props: any) => {
  const { t, mobile } = props;
  const [coupon, setCoupon] = useState('');
  const [openFilter, setOpenFilter] = useState(false);
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('success');
  const [loading, setLoading] = useState(false);
  const [paging, setPaging] = useState(null as any);
  const [page, setPage] = useState(1);
  const [event, setEvent] = useState(null as any);
  const [status, setStatus] = useState(null as any);
  const [statusID, setStatusID] = useState('');
  const [couponList, setCouponList] = useState([] as any);
  const [filter, setFilter] = useState(true);

  const status_list = [
    {
      value: '',
      label: t('all-status')
    },
    {
      value: 'created',
      label: t('coupon-created')
    },
    {
      value: 'using',
      label: t('coupon-using')
    },
    {
      value: 'out_of_use',
      label: t('coupon-out-of-usage')
    },
    {
      value: 'expired',
      label: t('coupon-expired')
    },
  ] as any;

  const [events, setEvents] = useState([] as any);
  const [eventID, setEventID] = useState('');
  const { i18n: { language } = {} } = useTranslation();
  const [fetchData, setFetchData] = useState(false);

  const [searchParams] = useSearchParams();
  const paramsObject = Object.fromEntries([...searchParams]);
  const navigate = useNavigate();
  const [initParams, setInitParams] = useState(true);

  useEffect(() => {
    if (initParams) {
      const keys = Object.keys(paramsObject);

      if (keys?.length > 0) {
        handleInitSearchValue();
      } else {
        setFilter(true);
        setInitParams(false);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initParams]);

  const handleInitSearchValue = () => {
    if (paramsObject.q && paramsObject.q !== '') {
      setCoupon(paramsObject.q);
    }

    if (paramsObject.event_id && paramsObject.event_id !== '') {
      setEventID(paramsObject.event_id);
    }

    if (paramsObject.status && paramsObject.status !== '') {
      setStatusID(paramsObject.status);
    }

    const intervalInit = setInterval(() => {
      setFilter(true);
      setInitParams(false);
      clearInterval(intervalInit);
    }, 1000);

  }

  useEffect(() => {
    handleUpdateRoute();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coupon, eventID, statusID]);

  const handleUpdateRoute = () => {
    const newSearchParams = new URLSearchParams(searchParams);

    newSearchParams.set('q', coupon);
    newSearchParams.set('event_id', eventID);
    newSearchParams.set('status', statusID);

    const newParamsObject = Object.fromEntries([...newSearchParams]);

    const keys = Object.keys(newParamsObject);
    let changeRoute = false;

    if (keys?.length > 0) {
      keys?.forEach((key: string) => {
        if (newParamsObject[key] !== '') {
          changeRoute = true;
        }
      });
    }

    if (changeRoute || !initParams) {
      navigate(`?${newSearchParams.toString()}`, { replace: true });
    }
  }

  useEffect(() => {
    if (!initParams) {
      if (events?.length > 0) {
        if (eventID !== '') {
          events.forEach((item: any) => {
            if (item?.value === eventID) {
              setEvent(item);
            }
          })
        } else {
          setEvent(events[0]);
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initParams, eventID, events]);

  useEffect(() => {
    if (!initParams) {
      if (status_list?.length > 0) {
        if (statusID !== '') {
          status_list.forEach((item: any) => {
            if (item?.value === statusID) {
              setStatus(item);
            }
          })
        } else {
          setStatus(status_list[0]);
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initParams])

  useEffect(() => {
    const intervalFetchData = setInterval(() => {
      setFetchData(true);
      clearInterval(intervalFetchData);
    }, 100);
  }, []);

  useEffect(() => {
    if (fetchData) {
      fetchEventList();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchData]);

  const fetchEventList = async () => {
    setLoading(true);
    try {
      const result = await ApiService.get(`events`);

      if (result && result.length > 0) {
        const arr = [] as any;

        result.forEach((item: any) => {
          if (item.id !== 'all') {
            const obj = {
              value: item.id,
              label: get(item, `name_${language}`, '')
            };

            arr.push(obj);
          }
        });

        setEvent(arr[0]);
        setEvents(arr);
      } else {
        setLoading(false);
      }
    } catch (error: any) {
      setMessage(error.message);
      setMessageType('error');
      setLoading(false);
    }
  }

  const TABLE_HEAD = [t('no'), t('coupon-code'), t('max-time-used'), t('used'), t('remaining'), t('discount-condition'), t('status'), t('apply-from'), t('apply-to')];

  useEffect(() => {
    if (events.length > 0 && filter) {
      fetchCouponList();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [events, filter, page]);

  const fetchCouponList = async () => {
    setLoading(true);
    try {
      const result = await ApiService.get(`promotion/page/${page}?limit=10&q=${coupon}&event_id=${eventID}&status=${statusID}`);

      if (result) {
        setPaging(result.paging);
        setCouponList(result.coupons);
      }
    } catch (error: any) {
      setMessage(error.message);
      setMessageType('error');
    }

    setFilter(false);
    setLoading(false);
  }

  const handleExportData = async () => {
    setLoading(true);

    try {
      const result = await ApiService.get(`exports/coupon?limit=10&q=${coupon}&event_id=${event.value}&status=${status.value}`);

      if (result) {
        setMessage(t('export-success'));
        setMessageType('success');
      }
    } catch (error: any) {
      setMessage(error.message);
      setMessageType('error');
    }

    setLoading(false);
  }

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      setFilter(true);
    }
  };

  if (!mobile) {
    return (
      <div className="coupon-form" onKeyDown={handleKeyDown}>
        <div className="coupon-form__filter">
          <div className="filter-item coupon">
            <div className="filter-item__label">{t('coupon-code')}</div>

            <input className="filter-item__input"
              placeholder={t('placeholder-coupon-code')}
              value={coupon}
              onChange={(e: any) => setCoupon(e.target.value)} />
          </div>

          <div className="filter-item events">
            <div className="filter-item__label">{t('event-name')}</div>

            {
              (events.length > 0 && event) &&
              <Select
                value={event}
                name="events"
                options={events}
                className="filter-item__select"
                classNamePrefix="select"
                placeholder={events[0].label}
                onChange={(e: any) => {
                  setEvent(e);
                  setEventID(e.value);
                }} />
            }

          </div>

          <div className="filter-item status">
            <div className="filter-item__label">{t('status')}</div>

            <Select
              value={status}
              name="status"
              options={status_list}
              className="filter-item__select"
              classNamePrefix="select"
              placeholder={status_list[0].label}
              onChange={(e: any) => {
                setStatus(e);
                setStatusID(e.value);
              }} />
          </div>

          {/* <div className="filter-item apply-date">
            <div className="filter-item__label">{t('apply-date')}</div>

            <DateRangePicker
              quickSelectDate={true}
              mobile={mobile}
              setApplyDate={setApplyDate}
            />
          </div> */}

          <Button
            placeholder="Button search coupon"
            className="btn-search"
            onPointerEnterCapture="none" onPointerLeaveCapture="none"
            onClick={() => setFilter(true)}>
            <MagnifyingGlassIcon className="w-4 h-4 icon-search" />
            <span>{t('search')}</span>
          </Button>

          <Button
            placeholder="Button export coupon"
            className="btn-export"
            onPointerEnterCapture="none" onPointerLeaveCapture="none"
            onClick={handleExportData}>
            <span>{t('export-coupons')}</span>
          </Button>
        </div>

        <div className="coupon-form__board">
          <div className="h-full w-full overflow-scroll overflow-y-hidden">
            <table className="w-full min-w-max table-auto text-left">
              <thead>
                <tr>
                  {TABLE_HEAD.map((head) => (
                    <th
                      key={head}
                      className="p-4"
                    >
                      <Typography
                        variant="small"
                        className="table-label"
                        placeholder="Typography"
                        onPointerEnterCapture="none" onPointerLeaveCapture="none"
                      >
                        {head}
                      </Typography>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {
                  couponList.length > 0 &&
                  couponList.map((item: any, index: number) => {
                    const isLast = index === couponList.length - 1;
                    const classes = isLast ? "p-4" : "p-4 border-b border-blue-gray-50";

                    return (
                      <tr key={`event_${item.code}_${index}`} className="table-row">
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            placeholder="Typography"
                            onPointerEnterCapture="none" onPointerLeaveCapture="none"
                          >
                            {
                              index < 9 &&
                              <span>{paging.current_page - 1}{index + 1}</span>
                            }

                            {
                              index === 9 &&
                              <span>{paging.current_page}0</span>
                            }
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            placeholder="Typography"
                            onPointerEnterCapture="none" onPointerLeaveCapture="none"
                          >
                            {item.code}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            onPointerEnterCapture="none" onPointerLeaveCapture="none"
                            variant="small"
                            color="blue-gray"
                            placeholder="Typography"
                          >
                            {item.max_times_used}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            onPointerEnterCapture="none" onPointerLeaveCapture="none"
                            variant="small"
                            color="blue-gray"
                            placeholder="Typography"
                          >
                            {item.max_times_used - item.remaining}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            onPointerEnterCapture="none" onPointerLeaveCapture="none"
                            variant="small"
                            color="blue-gray"
                            placeholder="Typography"
                          >
                            {item.remaining}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            onPointerEnterCapture="none" onPointerLeaveCapture="none"
                            variant="small"
                            color="blue-gray"
                            placeholder="Typography"
                          >
                            {ConvertCurrency(item.discount_value)}

                            {
                              item.discount_type === 'percent' ?
                                <span>%</span>
                                :
                                <span>VND</span>
                            }
                          </Typography>
                        </td>
                        <td className={classes}>
                          {
                            item.status === 'created' &&
                            <div className="table-body__status created">{t('coupon-created')}</div>
                          }

                          {
                            item.status === 'using' &&
                            <div className="table-body__status using">{t('coupon-using')}</div>
                          }

                          {
                            item.status === 'out_of_use' &&
                            <div className="table-body__status out-of-usage">{t('coupon-out-of-usage')}</div>
                          }

                          {
                            item.status === 'expired' &&
                            <div className="table-body__status expired">{t('coupon-expired')}</div>
                          }
                        </td>
                        <td className={classes}>
                          <Typography
                            onPointerEnterCapture="none" onPointerLeaveCapture="none"
                            variant="small"
                            color="blue-gray"
                            placeholder="Typography"
                          >
                            {moment(item.apply_from).format('DD/MM/YYYY HH:mm:ss')}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            onPointerEnterCapture="none" onPointerLeaveCapture="none"
                            variant="small"
                            color="blue-gray"
                            placeholder="Typography"
                          >
                            {moment(item.apply_to).format('DD/MM/YYYY HH:mm:ss')}
                          </Typography>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>

          {
            paging &&
            <div className="board__bottom">
              <div className="board__bottom__total-item">{t('total')}: {paging.total_item}/{paging.total}</div>

              {
                paging.total_page > 1 &&
                <Pagination
                  setPage={setPage}
                  paging={paging}
                  setFilter={setFilter}
                />
              }
            </div>
          }
        </div>

        {
          message !== '' &&
          <Message
            message={message}
            messageType={messageType}
            setMessage={setMessage}
          />
        }

        {
          loading &&
          <Loader />
        }
      </div>
    )
  } else {
    return (
      <div className="coupon-form" onKeyDown={handleKeyDown}>
        <div className="coupon-form__filter">
          <div className="filter__header">
            <div className="filter__header__label">{t('coupon-management')}</div>

            <Popover placement="bottom" open={openFilter} handler={setOpenFilter}>
              <PopoverHandler>
                <MagnifyingGlassIcon className="w-5 h-5" />
              </PopoverHandler>
              <PopoverContent onPointerEnterCapture="none" onPointerLeaveCapture="none"
                className="w-96 popup-search-coupon" placeholder="PopoverContent">
                <div className="filter-item">
                  <div className="filter-item__label">{t('coupon-code')}</div>

                  <input type="text" className="filter-item__input"
                    placeholder={t('placeholder-coupon-code')}
                    onChange={(e: any) => setCoupon(e.target.value)}
                    value={coupon} />
                </div>

                <div className="filter-item">
                  <div className="filter-item__label">{t('event-name')}</div>

                  {
                    (events.length > 0 && event) &&
                    <Select
                      value={event}
                      name="events"
                      options={events}
                      className="filter-item__select"
                      classNamePrefix="select"
                      placeholder={events[0].label}
                      onChange={(e: any) => {
                        setEvent(e);
                        setEventID(e.value);
                      }} />
                  }

                </div>

                <div className="filter-item">
                  <div className="filter-item__label">{t('status')}</div>

                  <Select
                    value={status}
                    name="events"
                    options={status_list}
                    className="filter-item__select"
                    classNamePrefix="select"
                    placeholder={status_list[0].label}
                    onChange={(e: any) => {
                      setStatus(e);
                      setStatusID(e.value);
                    }} />
                </div>

                {/* <div className="filter-item">
                  <div className="filter-item__label">{t('apply-date')}</div>

                  <DateRangePicker
                    quickSelectDate={true}
                    mobile={mobile}
                    setApplyDate={setApplyDate}
                  />
                </div> */}

                <Button
                  placeholder="Button search coupon"
                  className="btn-search"
                  onPointerEnterCapture="none" onPointerLeaveCapture="none"
                  onClick={() => {
                    setFilter(true);
                    setOpenFilter(false);
                  }}>
                  <MagnifyingGlassIcon className="w-4 h-4 icon-search" />
                  <span>{t('search')}</span>
                </Button>

                <Button
                  placeholder="Button export coupon"
                  className="btn-export"
                  onPointerEnterCapture="none" onPointerLeaveCapture="none"
                  onClick={() => {
                    handleExportData();
                    setOpenFilter(false);
                  }}>
                  <span>{t('export-coupons')}</span>
                </Button>
              </PopoverContent>
            </Popover>
          </div>

        </div>

        <div className={openFilter ? "coupon-form__body overlay" : "coupon-form__body"}>
          {
            couponList.map((item: any, index: number) => {
              return (
                <div className="coupon-item" key={`coupon-item-${index}`}>
                  <div className="coupon-item__row">
                    <div className="coupon-item__row__label">{t('status')}</div>

                    {
                      (item.status === 'created') &&
                      <div className="coupon-item__row_value status created">{t('coupon-created')}</div>
                    }

                    {
                      (item.status === 'using') &&
                      <div className="coupon-item__row_value status using">{t('coupon-using')}</div>
                    }

                    {
                      (item.status === 'out_of_use') &&
                      <div className="coupon-item__row_value status out-of-usage">{t('coupon-out-of-usage')}</div>
                    }

                    {
                      (item.status === 'expired') &&
                      <div className="coupon-item__row_value status expired">{t('coupon-expired')}</div>
                    }
                  </div>

                  <div className="coupon-item__row">
                    <div className="coupon-item__row__label">{t('no')}</div>
                    <div className="coupon-item__row__value">{
                      index < 9 &&
                      <span>{paging.current_page - 1}{index + 1}</span>
                    }

                      {
                        index === 9 &&
                        <span>{paging.current_page}0</span>
                      }</div>
                  </div>

                  <div className="coupon-item__row">
                    <div className="coupon-item__row__label">{t('coupon-code')}</div>
                    <div className="coupon-item__row__value">{item.code}</div>
                  </div>

                  <div className="coupon-item__row">
                    <div className="coupon-item__row__label">{t('max-time-used')}</div>
                    <div className="coupon-item__row__value">{item.max_times_used}</div>
                  </div>

                  <div className="coupon-item__row">
                    <div className="coupon-item__row__label">{t('used')}</div>
                    <div className="coupon-item__row__value">{item.max_times_used - item.remaining}</div>
                  </div>

                  <div className="coupon-item__row">
                    <div className="coupon-item__row__label">{t('remaining')}</div>
                    <div className="coupon-item__row__value">{item.remaining}</div>
                  </div>

                  <div className="coupon-item__row">
                    <div className="coupon-item__row__label">{t('discount-condition')}</div>
                    <div className="coupon-item__row__value">
                      {ConvertCurrency(item.discount_value)}

                      {
                        item.discount_type === 'percent' ?
                          <span>%</span>
                          :
                          <span>VND</span>
                      }
                    </div>
                  </div>

                  <div className="coupon-item__row">
                    <div className="coupon-item__row__label">{t('apply-from')}</div>
                    <div className="coupon-item__row__value">{moment(item.apply_from).format('DD/MM/YYYY HH:mm:ss')}</div>
                  </div>

                  <div className="coupon-item__row">
                    <div className="coupon-item__row__label">{t('apply-to')}</div>
                    <div className="coupon-item__row__value">{moment(item.apply_to).format('DD/MM/YYYY HH:mm:ss')}</div>
                  </div>
                </div>
              )
            })
          }
        </div>

        {
          paging && paging.total_page > 1 &&
          <Pagination
            setPage={setPage}
            paging={paging}
            setFilter={setFilter}
          />
        }

        {
          message !== '' &&
          <Message
            message={message}
            messageType={messageType}
            setMessage={setMessage}
          />
        }

        {
          loading &&
          <Loader />
        }
      </div>
    )
  }

}

const Coupon = () => {
  const { t } = useTranslation();
  const windowWidth = useWindowWidth();

  if (windowWidth && windowWidth > 768) {
    return (
      <CouponUI
        t={t}
        CouponForm={CouponForm}
      />
    )
  } else {
    return (
      <CouponUIMobile
        t={t}
        CouponForm={CouponForm}
      />
    )
  }
}

export default Coupon;