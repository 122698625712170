'use client';

const ConvertCurrency = (
  price: number = 0,
  itemRates?: any
) => {
  let convertPrice = 0;

  const currency = localStorage.getItem('wsCurrency');

  const isUSD = (currency && currency.toLowerCase() === 'usd') ? true : false;

  if (isUSD) {

    if (!isNaN(itemRates) && itemRates > 0) {
      const usd = price / itemRates;
      convertPrice = +usd.toFixed(2);
    } else {
      convertPrice = price;
    }
  } else {
    convertPrice = price;
  }

  return convertPrice;
};

export default ConvertCurrency;
