import { useEffect, useState } from "react";
import DateRangePicker from "@components/common/DateRangePicker";
import { useTranslation } from "react-i18next";
import Select from 'react-select';
import { Card, Typography } from "@material-tailwind/react";
import ApiService from "@services/ApiService";
import { get } from "lodash";
import Message from "@components/common/Message";
import Loader from "@components/common/Loader";
import Pagination from "@components/common/Pagination";
import moment from "moment";
import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import { useNavigate, useSearchParams } from "react-router-dom";
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

const EventTab = (props: any) => {
  const { mobile, eventSelected } = props;
  const { t, i18n: { language } = {} } = useTranslation();
  const [applyDate, setApplyDate] = useState({
    start: '',
    end: ''
  });
  const [ticketTab, setTicketTab] = useState(0);
  const TABLE_HEAD = [t('no'), t('ticket-name'), t("ticket-phase"), t('price'), t('items'), t('gross-revenue-event'), t('discounts'), t('net-revenue-event')];
  const TABLE_HEAD_GROUP = [t('no'), t('group-name'), t("members"), t('paid'), t('gross-revenue-event'), t('discounts'), t('net-revenue-event'), t('expired-date'), t('status')];
  const [events, setEvents] = useState([] as any);
  const [eventID, setEventID] = useState('');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('success');
  const [tickets, setTickets] = useState([] as any);
  const [ticketID, setTicketID] = useState('');
  const [ticketPhases, setTicketPhases] = useState([] as any);
  const [ticketPhaseID, setTicketPhaseID] = useState('');
  const [pagingTickets, setPagingTickets] = useState(null as any);
  const [ticketsPage, setTicketsPage] = useState(0);
  const [ticketsBoard, setTicketBoard] = useState([] as any);
  const [categories, setCategories] = useState([] as any);
  const [category, setCategory] = useState(null as any);
  const [tShirtInfo, setTShirtInfo] = useState([] as any);
  const [tShirtPage, setTShirtPage] = useState(0);
  const [tShirtPaging, setTShirtPaging] = useState(null as any);
  const [attendantInfo, setAttendantInfo] = useState([] as any);
  const [attendantPage, setAttendantPage] = useState(1);
  const [attendantPaging, setAttendantPaging] = useState(null as any);
  const [orderPending, setOrderPending] = useState([] as any);
  const [filter, setFilter] = useState(false);
  const [symbolCurrency, setSymbolCurrency] = useState('đ');
  const [currentCurrency, setCurrentCurrency] = useState('vnd');

  useEffect(() => {
    const wsCurrency = localStorage.getItem('wsCurrency');

    if (wsCurrency && wsCurrency.toLowerCase() === 'usd') {
      setSymbolCurrency('$');
      setCurrentCurrency('usd');
    } else {
      setSymbolCurrency('đ');
      setCurrentCurrency('vnd');
    }
  }, []);

  const group_status_list = [
    {
      value: '',
      label: t('all')
    },
    {
      value: '1',
      label: t('group-opening')
    },
    {
      value: '2',
      label: t('group-sending')
    },
    {
      value: '3',
      label: t('group-closed')
    },
    {
      value: '4',
      label: t('group-success')
    },
    {
      value: '0',
      label: t('group-canceled')
    }
  ] as any;

  const genders = [
    {
      value: '',
      label: t('all')
    },
    {
      value: 'Nam/ Male',
      label: t('Nam/ Male')
    },
    {
      value: 'Nữ/ Female',
      label: t('Nữ/ Female')
    }
  ] as any;

  const ticketTypes = [
    {
      value: '',
      label: t('all-types')
    },
    {
      value: 'individual',
      label: t('individual-type')
    },
    {
      value: 'group_buy',
      label: t('group-type')
    },
    {
      value: 'change_distance',
      label: t('change-distance-type')
    },
    {
      value: 'transfer_ticket',
      label: t('transfer-type')
    },
  ] as any;

  const [ticketTypeSelected, setTicketTypeSelected] = useState('');

  const [gender, setGender] = useState('');

  const [groupStatus, setGroupStatus] = useState(group_status_list[0] as any);
  const [groups, setGroups] = useState([] as any);
  const [groupName, setGroupName] = useState(null as any);
  const [groupStatistical, setGroupStatistical] = useState(null as any);
  const [tShirtSize, setTShirtSize] = useState(null as any);
  const [tShirtSizeSelected, setTshirtSizeSelected] = useState('');
  const [eventType, setEventType] = useState('');
  const [searchParams] = useSearchParams();
  const paramsObject = Object.fromEntries([...searchParams]);
  const navigate = useNavigate();
  const [initParams, setInitParams] = useState(true);
  const [eventDefault, setEventDefault] = useState(null as any);
  const [suffix, setSuffix] = useState('');

  useEffect(() => {
    if (initParams) {
      const keys = Object.keys(paramsObject);

      if (keys?.length > 0) {
        handleInitSearchValue();
      } else {
        setFilter(true);
        setInitParams(false);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initParams]);

  const handleInitSearchValue = () => {
    if (paramsObject.from_date && paramsObject.from_date !== '') {
      setApplyDate({
        start: paramsObject.from_date,
        end: paramsObject.to_date
      })
    }

    if (paramsObject.eventID && paramsObject.eventID !== '') {
      setEventID(paramsObject.eventID);
    }

    setInitParams(false);

    const intervalShowEvent = setInterval(() => {
      setFilter(true);
      clearInterval(intervalShowEvent);
    }, 1000);

  }

  useEffect(() => {
    if (events?.length > 0) {
      if (eventID !== '') {
        events.forEach((item: any) => {
          if (item.value === eventID) {
            setEventDefault(item);
          }
        })
      } else {
        setEventDefault(events[0]);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventID, events]);

  useEffect(() => {
    handleUpdateRoute();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventID])

  const handleUpdateRoute = () => {
    const newSearchParams = new URLSearchParams(searchParams);

    newSearchParams.set('from_date', applyDate.start);
    newSearchParams.set('to_date', applyDate.end);
    newSearchParams.set('event_id', eventID);

    const newParamsObject = Object.fromEntries([...newSearchParams]);

    const keys = Object.keys(newParamsObject);
    let changeRoute = false;

    if (keys?.length > 0) {
      keys?.forEach((key: string) => {
        if (newParamsObject[key] !== '') {
          changeRoute = true;
        }
      });
    }

    if (changeRoute || !initParams) {
      navigate(`?${newSearchParams.toString()}`, { replace: true });
      setFilter(true);
    }
  }

  useEffect(() => {
    if (events.length === 0) {
      fetchEvents();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [events, eventSelected]);

  const fetchEvents = async () => {
    try {
      const result = await ApiService.get(`events`);

      if (result && result.length > 0) {
        const arr = [] as any;

        result.forEach((item: any) => {
          if (item.id !== "all") {
            const obj = {
              value: item.id,
              label: get(item, `name_${language}`, ''),
              type: item.event_type
            };

            arr.push(obj);
          }
        })

        if (arr.length > 0) {
          setEvents(arr);

          if (eventSelected && eventSelected !== '') {
            setEventID(eventSelected);
          } else {
            setEventID(arr[0].value);
            setEventType(arr[0].type);
          }
        }

      } else {
        setEvents([]);
        setEventID('');
        setLoading(false);
      }
    } catch (error: any) {
      setMessage(error.message);
      setMessageType('error');
      setLoading(false);
    }
  }

  useEffect(() => {
    if (events.length > 0 && ticketTab === 0 && eventID !== '') {
      fetchTickets();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [events, ticketTab, eventID]);

  const fetchTickets = async () => {
    setLoading(true);
    try {
      const result = await ApiService.get(`dashboards/orders/${eventID}/ticket-name`);

      if (result && result.length > 0) {
        const arr = [
          {
            value: '',
            label: t('all-ticket')
          }
        ] as any;

        result.forEach((item: any) => {
          const obj = {
            value: get(item, `product_name_${language}`, ''),
            label: get(item, `product_name_${language}`, '')
          };

          arr.push(obj);
        });

        setTickets([...arr]);
        fetchTicketPhases();
      } else {
        setTickets([]);
        setLoading(false);
      }
    } catch (error: any) {
      setMessage(error.message);
      setMessageType('error');
      setLoading(false);
    }
  }

  const fetchTicketPhases = async () => {
    try {
      const result = await ApiService.get(`dashboards/orders/${eventID}/ticket-phase`);

      if (result && result.length > 0) {
        const arr = [
          {
            value: '',
            label: t('all-phases')
          }
        ] as any;

        result.forEach((item: any) => {
          const obj = {
            value: item.id,
            label: get(item, `name_${language}`, '')
          };

          arr.push(obj);
        });

        setTicketPhases([...arr]);
        if (ticketsPage !== 1) {
          setTicketsPage(1);
        } else {
          setLoading(false);
        }
      } else {
        setTicketsPage(1);
        setTicketPhases([]);
        setLoading(false);
      }
    } catch (error: any) {
      setMessage(error.message);
      setMessageType('error');
      setLoading(false);
    }
  }

  useEffect(() => {
    async function fetchTicketBoard() {
      setLoading(true);
      try {
        const result = await ApiService.get(`dashboards/orders/${eventID}/tickets/page/${ticketsPage}?limit=10&ticket_phase=${ticketPhaseID}&ticket_name=${ticketID}&from_date=${applyDate.start}&to_date=${applyDate.end}&order_type=${ticketTypeSelected}&currency=${currentCurrency}`);

        if (result && result.tickets) {
          if (result.tickets.length > 0) {
            setPagingTickets(result.paging);
            setTicketBoard(result.tickets);
          } else {
            setPagingTickets(null);
            setTicketBoard([]);
          }
        }
        setLoading(false);
      } catch (error: any) {
        setMessage(error.message);
        setMessageType('error');
        setLoading(false);
      }

      setFilter(false);
    }
    if (ticketsPage > 0) {
      fetchTicketBoard();
    }
  }, [ticketsPage, ticketPhaseID, ticketID, applyDate, eventID, ticketTypeSelected, filter]);

  const handleExportTicket = async () => {
    setLoading(true);
    const wsCurrency = localStorage.getItem('wsCurrency');

    const currentCurrency = (wsCurrency && wsCurrency !== '') ? wsCurrency : 'vnd';

    try {
      const result = await ApiService.get(`exports/ticket?ticket_phase=${ticketPhaseID}&ticket_name=${ticketID}&from_date=${applyDate.start}&to_date=${applyDate.end}&event_id=${eventID}&order_type=${ticketTypeSelected}&currency=${currentCurrency}`);

      if (result) {
        setMessage(t('export-success'));
        setMessageType('success');
      }
    } catch (error: any) {
      setMessage(error.message);
      setMessageType('error');
    }
    setLoading(false);
  }

  useEffect(() => {
    if (eventID !== '') {
      fetchCategories();
      fetchTShirtSize();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventID]);

  const fetchCategories = async () => {
    try {
      const result = await ApiService.get(`events/${eventID}/categories`);

      if (result && result.length > 0) {
        const arr = [
          {
            value: '',
            label: t('all')
          }
        ] as any;

        result.forEach((item: any) => {
          const obj = {
            value: item.category_en,
            label: get(item, `category_${language}`, '')
          };

          arr.push(obj);
        });

        setCategories([...arr]);
        setCategory(arr[0]);
        setTShirtPage(1);
      } else {
        setCategories([]);
        setTShirtPage(1);
      }

    } catch (error: any) {
      setMessage(error.message);
      setMessageType('error');
    }
  }

  const fetchTShirtSize = async () => {
    try {
      const result = await ApiService.get(`dashboards/orders/${eventID}/t-shirt-sizes`);

      if (result && result.length > 0) {
        const arr = [
          {
            value: '',
            label: t('all')
          }
        ] as any;

        result.forEach((item: any) => {
          const obj = {
            value: item.size_name_en,
            label: item.size_name_en
          };

          arr.push(obj)
        });

        setTShirtSize([...arr]);
      }

    } catch (error: any) {
      setMessage(error.message);
      setMessageType('error');
    }
  }

  useEffect(() => {
    if (tShirtPage > 0) {
      fetchTShirtInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tShirtPage, eventID, category, gender, tShirtSizeSelected, applyDate, filter]);

  const fetchTShirtInfo = async () => {
    try {
      const cate = (category && category.value) ? category.value : '';
      const result = await ApiService.get(`dashboards/orders/${eventID}/t-shirt-size/summary/page/${tShirtPage}?limit=10&category=${cate}&gender=${gender}&t_shirt_size=${tShirtSizeSelected}&from_date=${applyDate.start}&to_date=${applyDate.end}`);

      if (result && result.data) {
        setTShirtPaging(result.paging);
        if (result.data.length > 0) {
          setTShirtInfo(result.data);
        } else {
          setTShirtInfo([]);
        }
      }

    } catch (error: any) {
      setMessage(error.message);
      setMessageType('error');
    }
  }

  const handleExportData = async () => {
    setLoading(true);

    try {
      const cate = (category && category.value) ? category.value : '';
      const result = await ApiService.get(`exports/t-shirt-size?category=${cate}&gender=${gender}&t_shirt_size=${tShirtSizeSelected}&from_date=${applyDate.start}&to_date=${applyDate.end}&event_id=${eventID}`);

      if (result) {
        setMessage(t('export-success'));
        setMessageType('success');
      }
    } catch (error: any) {
      setMessage(error.message);
      setMessageType('error');
    }

    setLoading(false);
  }

  useEffect(() => {
    async function fetchAttendantInfo() {
      try {
        const result = await ApiService.get(`dashboards/orders/${eventID}/participants/summary/page/${attendantPage}?limit=10&from_date=${applyDate.start}&to_date=${applyDate.end}`);

        if (result && result.data && result.data.length > 0) {
          setAttendantPaging(result.paging);
          setAttendantInfo(result.data);
        } else {
          setAttendantInfo([]);
          setAttendantPaging(null);
        }

      } catch (error: any) {
        setMessage(error.message);
        setMessageType('error');
      }
    }

    if (eventID !== '') {
      fetchAttendantInfo();
    }
  }, [attendantPage, eventID, applyDate, filter]);

  useEffect(() => {
    async function fetchOrderPending() {
      try {
        const result = await ApiService.get(`dashboards/orders/${eventID}/order-pending?from_date=${applyDate.start}&to_date=${applyDate.end}&currency=${currentCurrency}`);

        if (result) {
          setOrderPending(result);
        }

      } catch (error: any) {
        setMessage(error.message);
        setMessageType('error');
      }
    }

    if (eventID !== '') {
      fetchOrderPending();
    }
  }, [eventID, applyDate, filter]);

  const shortenNumber = (num: number, type?: any) => {
    if (num > 0) {
      if (num >= 1000000000000) {
        let shortened = (num / 1000000000000) as any;
        shortened = (Math.floor(shortened * 10) / 10).toFixed(1);
        return shortened.endsWith('.0') ? shortened.slice(0, -2) + 'T' : shortened + 'T';
      } else if (num >= 1000000000) {
        let shortened = (num / 1000000000) as any;
        shortened = (Math.floor(shortened * 10) / 10).toFixed(1);
        return shortened.endsWith('.0') ? shortened.slice(0, -2) + 'B' : shortened + 'B';
      } else if (num >= 1000000) {
        let shortened = (num / 1000000) as any;
        shortened = (Math.floor(shortened * 10) / 10).toFixed(1);
        return shortened.endsWith('.0') ? shortened.slice(0, -2) + 'M' : shortened + 'M';
      } else if (num >= 1000) {
        let shortened = (num / 1000) as any;
        shortened = (Math.floor(shortened * 10) / 10).toFixed(1);
        return shortened.endsWith('.0') ? shortened.slice(0, -2) + 'K' : shortened + 'K';
      } else {
        if (type && type === 'number') {
          return num;
        } else {
          return num.toFixed(2);
        }
      }
    } else {
      return 0;
    }
  }

  const getPaymentMethod = (method: string) => {
    const payment_method = method.toLowerCase();
    switch (payment_method) {
      case 'atm':
        return t('atm');
      case 'bank_transfer':
        return t('bank-transfer');
      case 'credit':
        return t('credit');
      case 'partner_vnpay':
        return 'Partner VNPay';
      case 'vn_pay_b':
        return 'VNPAY';
      case 'vnpay':
        return 'QR Code';
      case 'zalopay':
        return 'ZaloPay';
      case 'viet_qr':
        return 'Viet QR';
      case 'momo':
        return t('momo');
      case 'voucher':
        return t('coupon');
      case 'shipcode':
        return 'Ship Code';
      case 'free':
        return t('free-ticket');
      case 'bnpl':
        return t('bnpl');
      case 'viettel_money':
        return t('viettel_money');
      case 'v_atm':
        return t('v_atm');
      case 'v_credit':
        return t('v_credit');
      case 'payoo_atm':
        return t('payoo_atm');
      case 'payoo_credit':
        return t('payoo_credit');
      case 'onepay_viet_qr':
        return t('onepay_viet_qr');
      default:
        return method;
    }
  }

  useEffect(() => {
    async function fetchGroupName() {
      setLoading(true);
      try {
        const result = await ApiService.get(`dashboards/orders/groups-info/${eventID}/name`);

        if (result && result.length > 0) {
          const arr = [
            {
              value: '',
              label: t('all')
            }
          ] as any;

          result.forEach((item: any) => {
            const obj = {
              value: get(item, `name_${language}`, ''),
              label: get(item, `name_${language}`, '')
            };

            arr.push(obj)
          });

          setGroups([...arr]);
          setGroupName(arr[0]);
        } else {
          setLoading(false);
        }

      } catch (error: any) {
        setMessage(error.message);
        setMessageType('error');
        setLoading(false);
      }
    }

    if (eventID !== '' && ticketTab === 1) {
      fetchGroupName();
    }
  }, [eventID, ticketTab, language, t]);

  useEffect(() => {
    async function fetchGroupStats() {
      setLoading(true);
      try {
        const result = await ApiService.get(`dashboards/orders/groups-info/${eventID}/statistical?group_status=${groupStatus.value}&q=${groupName.value}&from_date=${applyDate.start}&to_date=${applyDate.end}&currency=${currentCurrency}`);

        if (result) {
          setGroupStatistical(result);
        }

      } catch (error: any) {
        setMessage(error.message);
        setMessageType('error');
      }
      setLoading(false);
    }

    if (groupName) {
      fetchGroupStats();
    }
  }, [groupName, groupStatus, eventID, applyDate]);

  const handleExportGroup = async () => {
    setLoading(true);

    const wsCurrency = localStorage.getItem('wsCurrency');

    const currentCurrency = (wsCurrency && wsCurrency !== '') ? wsCurrency : 'vnd';

    try {
      const group = groupName;
      let group_name = group ? group?.value : '';

      const result = await ApiService.get(`exports/group?group_name=${group_name}&group_status=${groupStatus.value}&from_date=${applyDate.start}&to_date=${applyDate.end}&event_id=${eventID}&currency=${currentCurrency}`);

      if (result) {
        setMessage(t('export-success'));
        setMessageType('success');
      }
    } catch (error: any) {
      setMessage(error.message);
      setMessageType('error');
    }

    setLoading(false);
  }

  const getEventsPlaceholder = () => {
    let placeholder = events[0].label;

    events.forEach((item: any) => {
      if (eventSelected && eventSelected !== '' && eventSelected === item.value) {
        placeholder = item.label;
      }
    });

    return placeholder;
  }

  return (
    <div id="event-tab">
      <div className="filter-event">
        {
          events.length > 0 &&
          <div className="filter-event__item">
            <div className="filter-event__item__label">{t('event-name')}</div>

            {
              eventDefault &&
              <Select
                defaultValue={eventDefault}
                name="event"
                options={events}
                className="filter-item__select"
                classNamePrefix="select"
                placeholder={getEventsPlaceholder()}
                onChange={(e: any) => {
                  setEventID(e.value);
                  setTShirtPage(0);
                  setCategory(null);
                  setAttendantPage(1);
                  setEventType(e.type);
                }} />
            }

          </div>
        }

        <div className="filter-event__item date">
          <div className="filter-event__item__label">{t('time')}</div>

          <DateRangePicker
            quickSelectDate={true}
            setApplyDate={setApplyDate}
            mobile={mobile}
            suffix={suffix}
            setSuffix={setSuffix}
            setFilter={setFilter}
          />
        </div>

        <div className="h-[42px] rounded-[4px] px-[20px] border border-[#A6A1FD] flex items-center justify-center gap-x-[4px] mt-[30px] ml-[10px] cursor-pointer" onClick={() => setFilter(true)}>
          <MagnifyingGlassIcon className="size-[20px] text-[#000]" strokeWidth={2} />

          <span className="text-14 text-[#081774]">{t('search')}</span>
        </div>
      </div>

      <div className="ticket-group">
        <div className="ticket-group__header">
          <div
            className={(ticketTab === 0) ? "ticket-group__header__tab tab-active" : "ticket-group__header__tab"}
            onClick={() => setTicketTab(0)}>{t('ticket')}</div>
          <div
            className={(ticketTab === 1) ? "ticket-group__header__tab tab-active" : "ticket-group__header__tab"}
            onClick={() => setTicketTab(1)}>{t('group')}</div>
        </div>

        {
          ticketTab === 0 &&
          <div className="ticket-group__filter">
            {
              tickets.length > 0 &&
              <div className="ticket-group__filter-tickets">
                <div className="filter-tickets__label">{t('ticket-name')}</div>

                <Select
                  defaultValue=""
                  name="tickets"
                  options={tickets}
                  className="filter-item__select"
                  classNamePrefix="select"
                  placeholder={tickets[0].label}
                  onChange={(e: any) => {
                    setTicketID(e.value);
                    setTicketsPage(1);
                  }} />
              </div>
            }

            {
              ticketPhases.length > 0 &&
              <div className="ticket-group__filter-tickets-phase">
                <div className="filter-tickets__label">{t('ticket-phase')}</div>

                <Select
                  defaultValue=""
                  name="ticket phases"
                  options={ticketPhases}
                  className="filter-item__select"
                  classNamePrefix="select"
                  placeholder={ticketPhases[0].label}
                  onChange={(e: any) => {
                    setTicketPhaseID(e.value);
                    setTicketsPage(1);
                  }} />
              </div>
            }

            {
              eventType === 'sports' &&
              <div className="ticket-group__filter-tickets-phase">
                <div className="filter-tickets__label">{t('ticket-type')}</div>

                <Select
                  defaultValue=""
                  name="ticket phases"
                  options={ticketTypes}
                  className="filter-item__select"
                  classNamePrefix="select"
                  placeholder={ticketTypes[0].label}
                  onChange={(e: any) => {
                    setTicketTypeSelected(e.value);
                    setTicketsPage(1);
                  }} />
              </div>
            }

            <div className={`${mobile ? 'w-[100%]' : 'w-[145px] ml-[10px] mt-[40px]'} h-[38px] rounded-[4px] border-[1px] border-[#A6A1FD] flex items-center justify-center cursor-pointer text-[#081774] btn-export-group px-[20px]`} onClick={handleExportTicket}>
              <img src="/images/icons/icon-download.svg" className="mr-[4px]" alt="icon-download" />

              <div className="text-[14px]">{t('export-report')}</div>
            </div>

          </div>
        }

        {
          (ticketTab === 1) &&
          <div className="ticket-group__filter">
            <div className="filter-group__row">
              <div className="group-summary">
                <div className="group-summary__icon-group">
                  <img src="/images/icons/user-group-event.svg" alt="icon-total-group" />
                </div>

                <div className="group-summary__info">
                  <div className="group-summary__info__label">{t('total-group')}</div>
                  {
                    groupStatistical && groupStatistical.total_group ?
                      <div className="group-summary__info__value">{groupStatistical.total_group}</div>
                      :
                      <div className="group-summary__info__value">0</div>
                  }
                </div>
              </div>

              <div className="group-summary">
                <div className="group-summary__icon-group total-group-payment">
                  <img src="/images/icons/group-payment.svg" alt="icon-total-group" />
                </div>

                <div className="group-summary__info">
                  <div className="group-summary__info__label">{t('total-group-payment')}</div>
                  {
                    groupStatistical && groupStatistical.total_paid_group ?
                      <div className="group-summary__info__value">{groupStatistical.total_paid_group}</div>
                      :
                      <div className="group-summary__info__value">0</div>
                  }
                </div>
              </div>

              <div className="group-summary">
                <div className="group-summary__icon-group total-member">
                  <img src="/images/icons/member-event.svg" alt="icon-total-group" />
                </div>

                <div className="group-summary__info">
                  <div className="group-summary__info__label">{t('total-member')}</div>
                  {
                    groupStatistical && groupStatistical.total_member ?
                      <div className="group-summary__info__value">{groupStatistical.total_member}</div>
                      :
                      <div className="group-summary__info__value">0</div>
                  }
                </div>
              </div>

              <div className="group-summary">
                <div className="group-summary__icon-group total-member">
                  <img src="/images/icons/member-payment-event.svg" alt="icon-total-group" />
                </div>

                <div className="group-summary__info">
                  <div className="group-summary__info__label">{t('total-member-payment')}</div>
                  {
                    groupStatistical && groupStatistical.total_paid_member ?
                      <div className="group-summary__info__value">{groupStatistical.total_paid_member}</div>
                      :
                      <div className="group-summary__info__value">0</div>
                  }
                </div>
              </div>

            </div>

            <div className="filter-group__row">
              {
                groups.length > 0 &&
                <div className="ticket-group__filter-group">
                  <div className="filter-tickets__label">{t('group-name')}</div>

                  <Select
                    defaultValue=""
                    name="tickets"
                    options={groups}
                    className="filter-item__select"
                    classNamePrefix="select"
                    placeholder={groups[0].label}
                    onChange={(e: any) => setGroupName(e)} />
                </div>
              }

              <div className="ticket-group__filter-group">
                <div className="filter-tickets__label">{t('group-status')}</div>

                <Select
                  defaultValue={groupStatus}
                  name="group status"
                  options={group_status_list}
                  className="filter-item__select"
                  classNamePrefix="select"
                  placeholder={group_status_list[0].label}
                  onChange={(e: any) => setGroupStatus(e)} />
              </div>

              <div className="w-[146px] h-[38px] rounded-[4px] border-[1px] border-[#A6A1FD] flex items-center justify-center cursor-pointer text-[#081774] btn-export-group" onClick={handleExportGroup}>
                <img src="/images/icons/icon-download.svg" className="mr-[4px]" alt="icon-download" />

                <div className="text-[14px]">{t('export-report')}</div>
              </div>
            </div>
          </div>
        }

        {
          (ticketTab === 0 && !mobile) &&
          <div className="ticket-group__board">
            <table className="w-full min-w-max table-auto text-left">
              <thead>
                <tr>
                  {TABLE_HEAD.map((head, i) => (
                    <th
                      key={head}
                      className={(i === 0) ? "board-column-head stt" : "board-column-head"}
                    >
                      <Typography
                        variant="small"
                        className="table-label"
                        placeholder="Typography"
                        onPointerEnterCapture="none" onPointerLeaveCapture="none"
                      >
                        {head}
                      </Typography>

                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {
                  ticketsBoard.length > 0 &&
                  ticketsBoard.map((item: any, index: number) => {

                    return (
                      <tr key={`event_item_${index}`} className="table-row">
                        <td className="stt">
                          <Typography
                            variant="small"
                            placeholder="Typography"
                            className="table-value"
                            onPointerEnterCapture="none" onPointerLeaveCapture="none"
                          >
                            {(index < 9) && pagingTickets.current_page - 1}{index + 1}
                          </Typography>
                        </td>

                        <td className="board-column">
                          <Typography
                            variant="small"
                            placeholder="Typography"
                            className="table-value"
                            onPointerEnterCapture="none" onPointerLeaveCapture="none"
                          >
                            {get(item, `name_${language}`, '')}
                          </Typography>
                        </td>

                        {
                          (item.ticket_phase_name_vi && item.ticket_phase_name_vi !== '') ?
                            <td className="board-column">
                              <Typography
                                onPointerEnterCapture="none" onPointerLeaveCapture="none"
                                variant="small"
                                className="table-value"
                                placeholder="Typography"
                              >
                                {get(item, `ticket_phase_name_${language}`, '')}
                              </Typography>
                            </td>
                            :
                            <td className="board-column">
                              <Typography
                                onPointerEnterCapture="none" onPointerLeaveCapture="none"
                                variant="small"
                                className="table-value"
                                placeholder="Typography"
                              >
                                N/A
                              </Typography>
                            </td>
                        }

                        <td className="board-column">
                          <Typography
                            onPointerEnterCapture="none" onPointerLeaveCapture="none"
                            variant="small"
                            className="table-value"
                            placeholder="Typography"
                          >
                            <LightTooltip title={`đ ${(+item.price > 0) ? item.price.toLocaleString('de-DE') : item.price}`} placement="top-start">
                              <span className="cursor-pointer">đ {shortenNumber(item.price)}</span>
                            </LightTooltip>
                          </Typography>
                        </td>

                        <td className="board-column">
                          <Typography
                            onPointerEnterCapture="none" onPointerLeaveCapture="none"
                            variant="small"
                            className="table-value"
                            placeholder="Typography"
                          >
                            <LightTooltip title={`${(+item.quantity > 0) ? item.quantity.toLocaleString('de-DE') : item.quantity}`} placement="top-start">
                              <span className="cursor-pointer">{shortenNumber(item.quantity, 'number')}</span>
                            </LightTooltip>
                          </Typography>
                        </td>

                        <td className="board-column">
                          <Typography
                            onPointerEnterCapture="none" onPointerLeaveCapture="none"
                            variant="small"
                            className="table-value"
                            placeholder="Typography"
                          >
                            <LightTooltip title={`${symbolCurrency} ${(+item.gross_revenue > 0) ? item.gross_revenue.toLocaleString('de-DE') : item.gross_revenue}`} placement="top-start">
                              <span className="cursor-pointer">{symbolCurrency} {shortenNumber(item.gross_revenue)}</span>
                            </LightTooltip>
                          </Typography>
                        </td>

                        <td className="board-column">
                          <Typography
                            onPointerEnterCapture="none" onPointerLeaveCapture="none"
                            variant="small"
                            className="table-value"
                            placeholder="Typography"
                          >
                            <LightTooltip title={`${symbolCurrency} ${(+item.discount_amount > 0) ? item.discount_amount.toLocaleString('de-DE') : item.discount_amount}`} placement="top-start">
                              <span className="cursor-pointer">{symbolCurrency} {shortenNumber(item.discount_amount)}</span>
                            </LightTooltip>
                          </Typography>
                        </td>

                        <td className="board-column">
                          <Typography
                            onPointerEnterCapture="none" onPointerLeaveCapture="none"
                            variant="small"
                            className="table-value"
                            placeholder="Typography"
                          >
                            <LightTooltip title={`${symbolCurrency} ${(+item.net_revenue > 0) ? item.net_revenue.toLocaleString('de-DE') : item.net_revenue}`} placement="top-start">
                              <span className="cursor-pointer">{symbolCurrency} {shortenNumber(item.net_revenue)}</span>
                            </LightTooltip>
                          </Typography>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>

            {
              pagingTickets &&
              <div className="board__bottom">
                {
                  pagingTickets.total_page > 1 &&
                  <Pagination
                    setPage={setTicketsPage}
                    paging={pagingTickets}
                  />
                }
              </div>
            }
          </div>
        }

        {
          (ticketTab === 0 && mobile) &&
          <div className="ticket-group__board">
            {
              ticketsBoard.length > 0 &&
              ticketsBoard.map((item: any, index: number) => {
                return (
                  <div className="ticket-group__item" key={`event_item_${index}`}>
                    <div className="ticket-group__item-row">
                      <div className="ticket-group__item-label">{t('no')}</div>
                      <div className="ticket-group__item-value">{(index < 9) && pagingTickets.current_page - 1}{index + 1}</div>
                    </div>

                    <div className="ticket-group__item-row">
                      <div className="ticket-group__item-label">{t('ticket-name')}</div>
                      <div className="ticket-group__item-value">{get(item, `name_${language}`, '')}</div>
                    </div>

                    <div className="ticket-group__item-row">
                      <div className="ticket-group__item-label">{t('ticket-phase')}</div>
                      {
                        (item.ticket_phase_name_vi && item.ticket_phase_name_vi !== '') ?
                          <div className="ticket-group__item-value">{get(item, `ticket_phase_name_${language}`, '')}</div>
                          :
                          <div className="ticket-group__item-value">N/A</div>
                      }
                    </div>

                    <div className="ticket-group__item-row">
                      <div className="ticket-group__item-label">{t('price')}</div>
                      <div className="ticket-group__item-value">{symbolCurrency} {shortenNumber(item.price)}</div>
                    </div>

                    <div className="ticket-group__item-row">
                      <div className="ticket-group__item-label">{t('quantity')}</div>
                      <div className="ticket-group__item-value">{item.quantity}</div>
                    </div>

                    <div className="ticket-group__item-row">
                      <div className="ticket-group__item-label">{t('gross-revenue-event')}</div>
                      <div className="ticket-group__item-value">{symbolCurrency} {shortenNumber(item.gross_revenue)}</div>
                    </div>

                    <div className="ticket-group__item-row">
                      <div className="ticket-group__item-label">{t('discounts')}</div>
                      <div className="ticket-group__item-value">{symbolCurrency} {shortenNumber(item.discount_amount)}</div>
                    </div>

                    <div className="ticket-group__item-row">
                      <div className="ticket-group__item-label">{t('net-revenue-event')}</div>
                      <div className="ticket-group__item-value">{symbolCurrency} {shortenNumber(item.net_revenue)}</div>
                    </div>
                  </div>
                )
              })
            }

            {
              pagingTickets &&
              <div className="board__bottom">
                {
                  pagingTickets.total_page > 1 &&
                  <Pagination
                    setPage={setTicketsPage}
                    paging={pagingTickets}
                  />
                }
              </div>
            }
          </div>
        }

        {
          (ticketTab === 1 && !mobile) &&
          <div className="ticket-group__board">
            <div className="h-full w-full overflow-scroll overflow-y-hidden">
              <table className="w-full min-w-max text-left table-auto">
                <thead>
                  <tr>
                    {TABLE_HEAD_GROUP.map((head, i) => (
                      <th
                        key={head}
                        className="p-4"
                      >
                        <Typography
                          variant="small"
                          className="table-label"
                          placeholder="Typography"
                          onPointerEnterCapture="none" onPointerLeaveCapture="none"
                        >
                          {head}

                          {
                            head === t('status') &&
                            <LightTooltip title={<span dangerouslySetInnerHTML={{ __html: t('tooltip-status-group') }} />} placement="left">
                              <img src="/images/icons/tooltip.svg" className="icon-tooltip" alt="icon-tooltip" />
                            </LightTooltip>
                          }
                        </Typography>

                      </th>
                    ))}
                  </tr>
                </thead>

                {
                  groupStatistical &&
                  <tbody>
                    {
                      (groupStatistical.statistical_data && groupStatistical.statistical_data.length > 0) &&
                      groupStatistical.statistical_data.map((item: any, index: number) => {

                        return (
                          <tr key={`event_${item.code}_${index}`} className="table-row">
                            <td className="stt p-4">
                              <Typography
                                variant="small"
                                className="table-value"
                                placeholder="Typography"
                                onPointerEnterCapture="none" onPointerLeaveCapture="none"
                              >
                                {index + 1}
                              </Typography>
                            </td>

                            <td className="p-4">
                              <Typography
                                variant="small"
                                className="table-value"
                                placeholder="Typography"
                                onPointerEnterCapture="none" onPointerLeaveCapture="none"
                              >
                                {get(item, `name_${language}`, '')}
                              </Typography>
                            </td>

                            <td className="p-4">
                              <Typography
                                onPointerEnterCapture="none" onPointerLeaveCapture="none"
                                variant="small"
                                className="table-value"
                                placeholder="Typography"
                              >
                                <LightTooltip title={`${item.attendant_quantity}`} placement="top-start">
                                  <span className="cursor-pointer">{shortenNumber(item.attendant_quantity, 'number')}</span>
                                </LightTooltip>
                              </Typography>
                            </td>

                            <td className="p-4">
                              <Typography
                                onPointerEnterCapture="none" onPointerLeaveCapture="none"
                                variant="small"
                                className="table-value"
                                placeholder="Typography"
                              >
                                <LightTooltip title={`${item.attendant_paid_quantity}`} placement="top-start">
                                  <span className="cursor-pointer">{shortenNumber(item.attendant_paid_quantity, 'number')}</span>
                                </LightTooltip>
                              </Typography>
                            </td>

                            <td className="p-4">
                              <Typography
                                onPointerEnterCapture="none" onPointerLeaveCapture="none"
                                variant="small"
                                className="table-value"
                                placeholder="Typography"
                              >
                                <LightTooltip title={`đ ${item.total_amount}`} placement="top-start">
                                  <span className="cursor-pointer">đ {shortenNumber(item.total_amount)}</span>
                                </LightTooltip>
                              </Typography>
                            </td>

                            <td className="p-4">
                              <Typography
                                onPointerEnterCapture="none" onPointerLeaveCapture="none"
                                variant="small"
                                className="table-value"
                                placeholder="Typography"
                              >
                                <LightTooltip title={`đ ${item.discount_amount}`} placement="top-start">
                                  <span className="cursor-pointer">đ {shortenNumber(item.discount_amount)}</span>
                                </LightTooltip>
                              </Typography>
                            </td>

                            <td className="p-4">
                              <Typography
                                onPointerEnterCapture="none" onPointerLeaveCapture="none"
                                variant="small"
                                className="table-value"
                                placeholder="Typography"
                              >
                                <LightTooltip title={`đ ${item.final_amount}`} placement="top-start">
                                  <span className="cursor-pointer">đ {shortenNumber(item.final_amount)}</span>
                                </LightTooltip>
                              </Typography>
                            </td>

                            <td className="p-4">
                              <Typography
                                onPointerEnterCapture="none" onPointerLeaveCapture="none"
                                variant="small"
                                className="table-value"
                                placeholder="Typography"
                              >
                                {moment(item.expired_date).format('DD/MM/YYYY HH:mm:ss')}
                              </Typography>
                            </td>

                            <td className="p-4">
                              <Typography
                                onPointerEnterCapture="none" onPointerLeaveCapture="none"
                                variant="small"
                                className="table-value"
                                placeholder="Typography"
                              >
                                {
                                  item.group_status === 0 &&
                                  <span className="group-status closed">{t('group-canceled')}</span>
                                }
                                {
                                  item.group_status === 1 &&
                                  <span className="group-status opening">{t('group-opening')}</span>
                                }
                                {
                                  item.group_status === 2 &&
                                  <span className="group-status sending">{t('group-sending')}</span>
                                }
                                {
                                  item.group_status === 3 &&
                                  <span className="group-status closed">{t('group-closed')}</span>
                                }
                                {
                                  item.group_status === 4 &&
                                  <span className="group-status success">{t('group-success')}</span>
                                }
                              </Typography>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                }

              </table>
            </div>
          </div>
        }

        {
          (ticketTab === 1 && groupStatistical && mobile) &&
          <div className="ticket-group__board">
            {
              (groupStatistical.statistical_data && groupStatistical.statistical_data.length > 0) &&
              groupStatistical.statistical_data.map((item: any, index: number) => {
                return (
                  <div className="group-statistical-item" key={`group_statistical_item_${index}`}>
                    <div className="group-statistical__row">
                      <div className="group-statistical__label">{t('no')}</div>
                      <div className="group-statistical__value">{index + 1}</div>
                    </div>

                    <div className="group-statistical__row">
                      <div className="group-statistical__label">{t('group-name')}</div>
                      <div className="group-statistical__value">{get(item, `name_${language}`, '')}</div>
                    </div>

                    <div className="group-statistical__row">
                      <div className="group-statistical__label">{t('members')}</div>
                      <div className="group-statistical__value">{item.attendant_quantity}</div>
                    </div>

                    <div className="group-statistical__row">
                      <div className="group-statistical__label">{t('paid')}</div>
                      <div className="group-statistical__value">{item.attendant_paid_quantity}</div>
                    </div>

                    <div className="group-statistical__row">
                      <div className="group-statistical__label">{t('gross-revenue-event')}</div>
                      <div className="group-statistical__value">đ {shortenNumber(item.total_amount)}</div>
                    </div>

                    <div className="group-statistical__row">
                      <div className="group-statistical__label">{t('discounts')}</div>
                      <div className="group-statistical__value">đ {shortenNumber(item.discount_amount)}</div>
                    </div>

                    <div className="group-statistical__row">
                      <div className="group-statistical__label">{t('net-revenue-event')}</div>
                      <div className="group-statistical__value">đ {shortenNumber(item.final_amount)}</div>
                    </div>

                    <div className="group-statistical__row">
                      <div className="group-statistical__label">{t('expired-date')}</div>
                      <div className="group-statistical__value">{moment(item.expired_date).format('DD/MM/YYYY HH:mm:ss')}</div>
                    </div>

                    <div className="group-statistical__row">
                      <div className="group-statistical__label">
                        <span>{t('status')}</span>

                        <LightTooltip title={<span dangerouslySetInnerHTML={{ __html: t('tooltip-status-group') }} />} placement="top-start">
                          <img src="/images/icons/tooltip.svg" className="ml-1" alt="icon-tooltip" />
                        </LightTooltip>
                      </div>
                      <div className="group-statistical__value">
                        {
                          item.group_status === 0 &&
                          <span className="group-status closed">{t('group-canceled')}</span>
                        }
                        {
                          item.group_status === 1 &&
                          <span className="group-status opening">{t('group-opening')}</span>
                        }
                        {
                          item.group_status === 2 &&
                          <span className="group-status sending">{t('group-sending')}</span>
                        }
                        {
                          item.group_status === 3 &&
                          <span className="group-status closed">{t('group-closed')}</span>
                        }
                        {
                          item.group_status === 4 &&
                          <span className="group-status success">{t('group-success')}</span>
                        }
                      </div>
                    </div>
                  </div>
                )
              })
            }
          </div>
        }

      </div>

      {
        (tShirtInfo && tShirtSize && tShirtSize.length > 0 && ticketTab === 0) &&
        <div className="info-group">
          <div className="info-group__header">
            <div className="info-group__title">{t('t-shirt-size-information')}</div>
          </div>

          <div className="info-group__body">
            <div className="info-group__filter">
              {
                (categories.length > 0 && category) &&
                <div className="filter-categories">
                  <div className="filter-categories__label">{t('category-event')}</div>

                  <Select
                    defaultValue={category}
                    name="categories"
                    options={categories}
                    className="filter-item__select"
                    classNamePrefix="select"
                    placeholder={categories[0].label}
                    onChange={(e: any) => {
                      setCategory(e);
                      setTShirtPage(1);
                    }} />
                </div>
              }

              <div className="filter-categories">
                <div className="filter-categories__label">{t('gender')}</div>

                <Select
                  defaultValue={genders[0]}
                  name="categories"
                  options={genders}
                  className="filter-item__select"
                  classNamePrefix="select"
                  placeholder={genders[0].label}
                  onChange={(e: any) => {
                    setGender(e.value);
                    setTShirtPage(1);
                  }} />
              </div>

              {
                (tShirtSize && tShirtSize.length > 0) &&
                <div className="filter-categories">
                  <div className="filter-categories__label">{t('t_shirt_size')}</div>

                  <Select
                    defaultValue={tShirtSize[0]}
                    name="categories"
                    options={tShirtSize}
                    className="filter-item__select"
                    classNamePrefix="select"
                    placeholder={tShirtSize[0].label}
                    onChange={(e: any) => {
                      setTshirtSizeSelected(e.value);
                      setTShirtPage(1);
                    }} />
                </div>
              }

              <div className="flex w-[146px] h-[38px] items-center justify-center rounded-[4px] border-[1px] border-[#A6A1FD] text-[#081774] cursor-pointer btn-export-t-shirt" onClick={handleExportData}>
                <img src="/images/icons/icon-download.svg" className="mr-[4px]" alt="icon-download" />

                <div className="text-[14px]">{t('export-report')}</div>
              </div>
            </div>

            {
              (tShirtInfo.length > 0 && !mobile) &&
              <div className="info-group__board">
                <table className="w-full min-w-max text-left">
                  <thead>
                    <tr>
                      <th className="p-4">
                        <Typography
                          variant="small"
                          className="table-label"
                          placeholder="Typography"
                          onPointerEnterCapture="none" onPointerLeaveCapture="none"
                        >
                          {t('no')}
                        </Typography>
                      </th>

                      <th className="p-4">
                        <Typography
                          variant="small"
                          className="table-label"
                          placeholder="Typography"
                          onPointerEnterCapture="none" onPointerLeaveCapture="none"
                        >
                          {t('t-shirt-size')}
                        </Typography>
                      </th>

                      <th className="p-4">
                        <Typography
                          variant="small"
                          className="table-label"
                          placeholder="Typography"
                          onPointerEnterCapture="none" onPointerLeaveCapture="none"
                        >
                          {t('category-event')}
                        </Typography>
                      </th>

                      <th className="p-4">
                        <Typography
                          variant="small"
                          className="table-label"
                          placeholder="Typography"
                          onPointerEnterCapture="none" onPointerLeaveCapture="none"
                        >
                          {t('gender')}
                        </Typography>
                      </th>

                      <th className="p-4">
                        <Typography
                          variant="small"
                          className="table-label"
                          placeholder="Typography"
                          onPointerEnterCapture="none" onPointerLeaveCapture="none"
                        >
                          {t('quantity')}
                        </Typography>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      tShirtInfo.length > 0 &&
                      tShirtInfo.map((item: any, index: number) => {
                        const isLast = index === tShirtInfo.length - 1;
                        const classes = isLast ? "p-4" : "p-4 border-b border-blue-gray-50";

                        return (
                          <tr key={`event_${item.code}_${index}`} className="table-row">
                            <td className={classes}>
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-normal"
                                placeholder="Typography"
                                onPointerEnterCapture="none" onPointerLeaveCapture="none"
                              >
                                {(index < 9) && tShirtPaging.current_page - 1}{index + 1}
                              </Typography>
                            </td>

                            <td className={classes}>
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-normal"
                                placeholder="Typography"
                                onPointerEnterCapture="none" onPointerLeaveCapture="none"
                              >
                                {item.t_shirt_size}
                              </Typography>
                            </td>

                            <td className={classes}>
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-normal"
                                placeholder="Typography"
                                onPointerEnterCapture="none" onPointerLeaveCapture="none"
                              >
                                {get(item, `category_name_${language}`, '')}
                              </Typography>
                            </td>

                            <td className={classes}>
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-normal"
                                placeholder="Typography"
                                onPointerEnterCapture="none" onPointerLeaveCapture="none"
                              >
                                {t(item.gender)}
                              </Typography>
                            </td>

                            <td className={classes}>
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-normal"
                                placeholder="Typography"
                                onPointerEnterCapture="none" onPointerLeaveCapture="none"
                              >
                                {item.quantity}
                              </Typography>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>

                {
                  tShirtPaging &&
                  <div className="board__bottom">
                    {
                      tShirtPaging.total_page > 1 &&
                      <Pagination
                        setPage={setTShirtPage}
                        paging={tShirtPaging}
                      />
                    }
                  </div>
                }
              </div>
            }

            {
              (tShirtInfo.length > 0 && mobile) &&
              <div className="info-group__board">
                {
                  tShirtInfo.length > 0 &&
                  tShirtInfo.map((item: any, index: number) => {
                    return (
                      <div className="t-shirt-item" key={`t_shirt_item_${index}`}>
                        <div className="t-shirt-row">
                          <div className="t-shirt-label">{t('no')}</div>
                          <div className="t-shirt-value">{(index < 9) && tShirtPaging.current_page - 1}{index + 1}</div>
                        </div>

                        <div className="t-shirt-row">
                          <div className="t-shirt-label">{t('t-shirt-size')}</div>
                          <div className="t-shirt-value">{item.t_shirt_size}</div>
                        </div>

                        <div className="t-shirt-row">
                          <div className="t-shirt-label">{t('category-event')}</div>
                          <div className="t-shirt-value">{get(item, `category_name_${language}`)}</div>
                        </div>

                        <div className="t-shirt-row">
                          <div className="t-shirt-label">{t('gender')}</div>
                          <div className="t-shirt-value">{t(item.gender)}</div>
                        </div>

                        <div className="t-shirt-row">
                          <div className="t-shirt-label">{t('quantity')}</div>
                          <div className="t-shirt-value">{item.quantity}</div>
                        </div>
                      </div>
                    )
                  })
                }

                {
                  tShirtPaging &&
                  <div className="board__bottom">
                    {
                      tShirtPaging.total_page > 1 &&
                      <Pagination
                        setPage={setTShirtPage}
                        paging={tShirtPaging}
                      />
                    }
                  </div>
                }
              </div>
            }

          </div>
        </div>
      }

      {
        (ticketTab === 0) &&
        <div className="info-group">
          <div className="info-group__header">
            <div className="info-group__title">{t('attendees-information')}</div>
          </div>

          <div className="info-group__body">

            {
              (attendantInfo.length > 0) &&
              <div className="info-group__board">
                {
                  !mobile ?
                    <table className="w-full min-w-max table-auto text-left">
                      <thead>
                        <tr>
                          <th className="p-4">
                            <Typography
                              variant="small"
                              className="table-label"
                              placeholder="Typography"
                              onPointerEnterCapture="none" onPointerLeaveCapture="none"
                            >
                              {t('no')}
                            </Typography>
                          </th>

                          <th className="p-4">
                            <Typography
                              variant="small"
                              className="table-label"
                              placeholder="Typography"
                              onPointerEnterCapture="none" onPointerLeaveCapture="none"
                            >
                              {t('category-event')}
                            </Typography>
                          </th>

                          <th className="p-4">
                            <Typography
                              variant="small"
                              className="table-label"
                              placeholder="Typography"
                              onPointerEnterCapture="none" onPointerLeaveCapture="none"
                            >
                              {t('quantity')}
                            </Typography>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          attendantInfo.length > 0 &&
                          attendantInfo.map((item: any, index: number) => {
                            const isLast = index === attendantInfo.length - 1;
                            const classes = isLast ? "p-4" : "p-4 border-b border-blue-gray-50";

                            return (
                              <tr key={`event_${item.code}_${index}`} className="table-row">
                                <td className={classes}>
                                  <Typography
                                    variant="small"
                                    color="blue-gray"
                                    className="font-normal"
                                    placeholder="Typography"
                                    onPointerEnterCapture="none" onPointerLeaveCapture="none"
                                  >
                                    {(index < 9) && attendantPaging.current_page - 1}{index + 1}
                                  </Typography>
                                </td>

                                <td className={classes}>
                                  <Typography
                                    variant="small"
                                    color="blue-gray"
                                    className="font-normal"
                                    placeholder="Typography"
                                    onPointerEnterCapture="none" onPointerLeaveCapture="none"
                                  >
                                    {get(item, `category_name_${language}`, '')}
                                  </Typography>
                                </td>

                                <td className={classes}>
                                  <Typography
                                    variant="small"
                                    color="blue-gray"
                                    className="font-normal"
                                    placeholder="Typography"
                                    onPointerEnterCapture="none" onPointerLeaveCapture="none"
                                  >
                                    {item.quantity}
                                  </Typography>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                    :
                    <div>
                      {
                        attendantInfo.map((item: any, index: number) => {
                          return (
                            <div className="participant-item" key={`participant_item_${index}`}>
                              <div className="participant-row">
                                <div className="participant-label">{t('no')}</div>
                                <div className="participant-value">{(index < 9) && attendantPaging.current_page - 1}{index + 1}</div>
                              </div>
                              <div className="participant-row">
                                <div className="participant-label">{t('category-event')}</div>
                                <div className="participant-value">{get(item, `category_name_${language}`, '')}</div>
                              </div>
                              <div className="participant-row">
                                <div className="participant-label">{t('quantity')}</div>
                                <div className="participant-value">{item.quantity}</div>
                              </div>
                            </div>
                          )
                        })
                      }
                    </div>
                }


                {
                  attendantPaging &&
                  <div className="board__bottom">
                    {
                      attendantPaging.total_page > 1 &&
                      <Pagination
                        setPage={setAttendantPage}
                        paging={attendantPaging}
                      />
                    }
                  </div>
                }
              </div>
            }

          </div>
        </div>
      }

      {
        (orderPending.length > 0 && ticketTab === 0) &&
        <div className="info-group">
          <div className="info-group__header">
            <div className="info-group__title">{t('pending-payment')}</div>
          </div>

          <div className="info-group__body">
            <div className="info-group__board">
              <Card
                onPointerEnterCapture="none" onPointerLeaveCapture="none"
                className="h-full w-full overflow-scroll overflow-y-hidden" placeholder="Card name table">
                <table className="w-full min-w-max table-auto text-left">
                  <thead>
                    <tr>
                      <th className="p-4">
                        <Typography
                          variant="small"
                          className="table-label"
                          placeholder="Typography"
                          onPointerEnterCapture="none" onPointerLeaveCapture="none"
                        >
                          {t('no')}
                        </Typography>
                      </th>

                      <th className="p-4">
                        <Typography
                          variant="small"
                          className="table-label"
                          placeholder="Typography"
                          onPointerEnterCapture="none" onPointerLeaveCapture="none"
                        >
                          {t('payment-method')}
                        </Typography>
                      </th>

                      <th className="p-4">
                        <Typography
                          variant="small"
                          className="table-label"
                          placeholder="Typography"
                          onPointerEnterCapture="none" onPointerLeaveCapture="none"
                        >
                          {t('total-orders')}
                        </Typography>
                      </th>

                      <th className="p-4">
                        <Typography
                          variant="small"
                          className="table-label"
                          placeholder="Typography"
                          onPointerEnterCapture="none" onPointerLeaveCapture="none"
                        >
                          {t('gross-revenue-event')}
                        </Typography>
                      </th>

                      <th className="p-4">
                        <Typography
                          variant="small"
                          className="table-label"
                          placeholder="Typography"
                          onPointerEnterCapture="none" onPointerLeaveCapture="none"
                        >
                          {t('net-revenue-event')}
                        </Typography>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      orderPending.length > 0 &&
                      orderPending.map((item: any, index: number) => {
                        const isLast = index === orderPending.length - 1;
                        const classes = isLast ? "p-4" : "p-4 border-b border-blue-gray-50";

                        return (
                          <tr key={`event_${item.code}_${index}`}>
                            <td className={classes}>
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-normal"
                                placeholder="Typography"
                                onPointerEnterCapture="none" onPointerLeaveCapture="none"
                              >
                                {index + 1}
                              </Typography>
                            </td>

                            <td className={classes}>
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-normal"
                                placeholder="Typography"
                                onPointerEnterCapture="none" onPointerLeaveCapture="none"
                              >
                                {getPaymentMethod(item.payment_method)}
                              </Typography>
                            </td>

                            <td className={classes}>
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-normal"
                                placeholder="Typography"
                                onPointerEnterCapture="none" onPointerLeaveCapture="none"
                              >
                                {item.quantity}
                              </Typography>
                            </td>

                            <td className={classes}>
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-normal"
                                placeholder="Typography"
                                onPointerEnterCapture="none" onPointerLeaveCapture="none"
                              >
                                {symbolCurrency} {shortenNumber(item.subtotal)}
                              </Typography>
                            </td>

                            <td className={classes}>
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-normal"
                                placeholder="Typography"
                                onPointerEnterCapture="none" onPointerLeaveCapture="none"
                              >
                                {symbolCurrency} {shortenNumber(item.grand_total)}
                              </Typography>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </Card>

              {
                attendantPaging &&
                <div className="board__bottom">
                  {
                    attendantPaging.total_page > 1 &&
                    <Pagination
                      setPage={setAttendantPage}
                      paging={attendantPaging}
                    />
                  }
                </div>
              }
            </div>

          </div>
        </div>
      }

      {
        message !== '' &&
        <Message
          message={message}
          messageType={messageType}
          setMessage={setMessage}
        />
      }

      {
        loading &&
        <Loader />
      }
    </div>
  )
}

export default EventTab;