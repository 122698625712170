import { useState, useEffect, useRef, useMemo } from 'react';
import useWindowWidth from '@helpers/InitWidthDevice';
import EditOrganizationInformationUI from 'UI/desktop/Organization/Information/Edit';
import EditOrganizationInformationUIMobile from 'UI/mobile/Organization/Information/Edit';
import { Button } from "@material-tailwind/react";
import Message from '@components/common/Message';
import { useTranslation } from 'react-i18next';
import { FileUploader } from "react-drag-drop-files";
import { PhotoIcon } from '@heroicons/react/24/outline';
import { Typography, Select, Option } from "@material-tailwind/react";
import Loader from '@components/common/Loader';
import ApiService from '@services/ApiService';
import { COUNTRY } from '@helpers/constants';
import Organization from '@helpers/Interface';
import { useNavigate } from 'react-router-dom';
import JoditEditor from 'jodit-react';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { XMarkIcon } from '@heroicons/react/24/solid';
import _ from 'lodash';
import moment from 'moment';

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

const fileTypes = ["JPG", "PNG", "JPEG"];
const fileLicenseType = ["JPG", "PNG", "JPEG", "PDF"];

const FormEditOrganization = (props: any) => {
  const { t, isMobile } = props;
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('success');

  const [error, setError] = useState({
    cover_banner: '',
    organization_logo: '',
    organization_banner: '',
    brand_logo: '',
    rectangular_promotion_banner: '',
    square_promotion_banner: '',
    seller_company_name: '',
    seller_company_email: ''
  } as any);
  const [loading, setLoading] = useState(false);
  const [organization, setOrganization] = useState({} as Organization);
  const navigate = useNavigate();
  const editor = useRef(null as any);
  const [companyLicense, setCompanyLicense] = useState([] as any);
  const [phoneCode, setPhoneCode] = useState('');

  const config = useMemo(
    () => ({
      readonly: false,
      style: {
        height: 'auto',
        overflow: 'auto'
      },
      uploader: {
        insertImageAsBase64URI: true
      },
    }),
    []
  );

  useEffect(() => {
    if (Object.keys(organization).length === 0) {
      fetchOrganizationDetail();
    }
  }, [organization]);

  const fetchOrganizationDetail = async () => {
    setLoading(true);
    try {
      const result = await ApiService.get(`organization`);

      if (result) {

        setOrganization(result);

        if (result?.seller_business_license && result?.seller_business_license.length > 0) {
          setCompanyLicense(result?.seller_business_license);
        }

        if (result?.seller_company_phone_area_code && result?.seller_company_phone_area_code !== '') {
          setPhoneCode(result?.seller_company_phone_area_code);
        } else {
          setPhoneCode('84');
        }
      }
    } catch (error: any) {
      setMessage(error.message);
      setMessageType('error');
    }

    setLoading(false);
  }

  const handleSelectImage = (file: any, type: string, width?: number, height?: number) => {
    setLoading(true);
    const errs = { ...error };
    const reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onload = (e: any) => {
      const img = new Image() as any;

      img.src = e.target.result;

      if (width && height) {
        img.onload = async function () {
          const img_height = this.height;
          const img_width = this.width;

          if (width !== img_width || height !== img_height) {
            errs[type] = t('error-upload-image', {
              size: `${width} x ${height}`,
            });

            setLoading(false);
          } else {
            errs[type] = '';
            handleUploadImage(file, type);
          }

          setError({ ...errs });

        }
      } else {
        errs[type] = '';
        handleUploadImage(file, type);

        setError({ ...errs });
      }

    };

  };

  const handleSelectLicense = async (files: any) => {
    setLoading(true);

    const fileKeys = Object.keys(files);
    const arr = [...companyLicense] as any;

    if (fileKeys.length > 0) {
      const uploadPromises = fileKeys.map((key) => handleUploadLicense(files[key]));

      const results = await Promise.all(uploadPromises);

      const validResults = results.filter((res) => res);

      setCompanyLicense([...arr, ...validResults]);
      setLoading(false);

    } else {
      setLoading(true);
    }

  }

  const handleUploadLicense = async (file: any) => {
    const data = new FormData();

    data.append('upload', file, `${file.name}`);

    try {
      const result = await ApiService.uploadImage(data);

      if (result) {
        return result.img_url;
      }
    } catch (error: any) {
      setMessage(error.message);
      setMessageType('error');
    }
  }

  const handleUploadImage = async (img: any, type: string) => {
    const data = new FormData();
    data.append('upload', img, `${img.name}`);

    try {
      const result = await ApiService.uploadImage(data);

      if (result) {
        handleChangeValue(result.img_url, type)
      }
    } catch (error: any) {
      setMessage(error.message);
      setMessageType('error');
    }

    setLoading(false);
  }

  const handleUploadImagePost = async (img: any) => {
    let res = '';
    const data = new FormData();
    data.append('upload', img, `${img.name}`);

    try {
      const result = await ApiService.uploadImage(data);

      if (result) {
        res = result.img_url
      }
    } catch (error: any) {
      setMessage(error.message);
      setMessageType('error');
    }

    return res;
  }

  const handleRemoveImage = (type: string) => {
    setLoading(true);

    const temp_file = { ...organization } as any;

    temp_file[type] = '';

    setOrganization({ ...temp_file });

    setLoading(false);
  }

  const handleSubmit = async () => {
    setLoading(true);

    const errKeys = Object.keys(error);
    let active = true;
    const errs = _.cloneDeep(error) as any;

    errKeys.forEach((key: string) => {
      if (error[key] !== '') {
        active = false
      }
    });

    if (active) {
      if (!organization?.seller_company_name || organization?.seller_company_name === '') {
        active = false;

        errs['seller_company_name'] = t('field-required');

        setError({ ...errs })
      }
    }

    if (active) {
      const submitData = { ...organization } as any;

      submitData.seller_business_issue_date = (submitData.seller_business_issue_date && submitData.seller_business_issue_date !== '') ? moment(submitData.seller_business_issue_date).format('YYYY-MM-DD') : submitData.seller_business_issue_date;

      submitData.seller_business_license = companyLicense;
      delete submitData.is_active;
      delete submitData.name;

      try {
        const result = await ApiService.put(`organization`, submitData);

        if (result) {
          setMessage(t("update-successfully"));
          setMessageType("success");

          const redirect = setInterval(() => {
            navigate("/organization/info");
            clearInterval(redirect);
          }, 2000);
        }
      } catch (error: any) {
        setMessage(error.message);
        setMessageType('error');
      }
    }

    setLoading(false);
  }

  const handleChangeValue = (val: any, type: string, lang?: string) => {
    const values = { ...organization } as any;
    const errs = _.cloneDeep(error);

    if (!lang) {
      const onlyNumbers = val.replace(/\D/g, '');

      if (type === 'seller_company_phone') {
        values[type] = onlyNumbers
      } else {
        values[type] = val;
      }

      if (type === 'seller_company_name') {
        if (!val || val === '') {
          errs[type] = t('field-required')
        } else {
          errs[type] = ''
        }
      }

      if (type === 'seller_company_email') {
        if (!val || val === '') {
          errs[type] = '';
        } else {
          const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,8}$/i;

          if (regex.test(val)) {
            errs[type] = '';
          } else {
            errs[type] = t('wrong-format-email');
          }
        }
      }

    } else {
      values[type][lang] = val;
    }

    setOrganization({ ...values });
    setError({ ...errs });
  }

  const dataURLtoFile = (dataurl: any, filename: any) => {

    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  const getLicenseName = (url: string) => {
    const position = url.lastIndexOf('/');

    const name = url.slice(position + 1);

    return name
  }

  const handleRemoveLicense = (url: string) => {
    const arr = _.cloneDeep(companyLicense) as any;

    const updatedFiles = arr.filter((item: string) => item !== url);

    setCompanyLicense([...updatedFiles]);
  }

  if (Object.keys(organization).length > 0) {
    return (
      <div className="w-full form-group">
        <div className="form">
          <div className="form-header">{t('edit')} {t('images-and-colors')}</div>

          <div className="form-body">
            <div className="form-row">
              <div className="form-row-item">
                <div className="form-label-group">
                  <span className="form-label">{t('cover-banner')} (1400 x 300)</span>

                  <LightTooltip title={t('tooltip-cover-image')} className="w-[200px]" placement='top-start'>
                    <img src="/images/icons/tooltip.svg" alt="icon-tooltip" />
                  </LightTooltip>
                </div>

                {
                  organization.cover_banner !== '' ?
                    <div className="image-uploaded-group" onClick={() => handleRemoveImage("cover_banner")}>
                      <img
                        src={organization.cover_banner}
                        alt="upload-organization"
                        className="image-uploaded"
                      />

                      <img src="/images/icons/icon-remove.png" className="btn-delete-image" alt="icon-remove" />

                    </div>
                    :
                    <FileUploader
                      handleChange={(e: any) => handleSelectImage(e, "cover_banner", 1400, 300)}
                      name="file"
                      types={fileTypes}
                      classes="upload-image-group"
                    >
                      <PhotoIcon className="h-8 w-8 icon-upload-image" />

                      <Typography
                        onPointerEnterCapture="none" onPointerLeaveCapture="none"
                        placeholder="Typography" className="label-upload-image">
                        {t("drag-and-drop")}
                      </Typography>

                      <div className="btn-select-image">{t("choose-image")}</div>
                    </FileUploader>
                }


                {
                  error.cover_banner !== '' &&
                  <div className="error">{error.cover_banner}</div>
                }

              </div>

              <div className="form-row-item">
                <div className="form-label-group">
                  <span className="form-label">{t('organization-logo')} (600 x 600)</span>

                  <LightTooltip title={t('tooltip-organization-logo')} className="w-[200px]" placement='top-start'>
                    <img src="/images/icons/tooltip.svg" alt="icon-tooltip" />
                  </LightTooltip>
                </div>

                {
                  organization.organization_logo !== '' ?
                    <div className="image-uploaded-group" onClick={() => handleRemoveImage("organization_logo")}>
                      <img
                        src={organization.organization_logo}
                        alt="upload-organization"
                        className="image-uploaded organization-logo"
                      />

                      <img src="/images/icons/icon-remove.png" className="btn-delete-image" alt="icon-remove" />
                    </div>
                    :
                    <FileUploader
                      handleChange={(e: any) => handleSelectImage(e, "organization_logo", 600, 600)}
                      name="file"
                      types={fileTypes}
                      classes="upload-image-group"
                    >
                      <PhotoIcon className="h-8 w-8 icon-upload-image" />

                      <Typography
                        onPointerEnterCapture="none" onPointerLeaveCapture="none"
                        placeholder="Typography" className="label-upload-image">
                        {t("drag-and-drop")}
                      </Typography>

                      <div className="btn-select-image">{t('choose-image')}</div>
                    </FileUploader>
                }

                {
                  error.organization_logo !== '' &&
                  <div className="error">{error.organization_logo}</div>
                }

              </div>
            </div>

            <div className="form-row">
              <div className="form-row-item">
                <div className="form-label-group">
                  <span className="form-label">{t('rectangular-promotion-banner')} (950 x 300)</span>

                  <LightTooltip title={t('tooltip-rectangular-promotion-banner')} className="w-[200px]" placement='top-start'>
                    <img src="/images/icons/tooltip.svg" alt="icon-tooltip" />
                  </LightTooltip>
                </div>

                {
                  organization.rectangular_promotion_banner && organization.rectangular_promotion_banner !== '' ?
                    <div className="image-uploaded-group" onClick={() => handleRemoveImage("rectangular_promotion_banner")}>
                      <img
                        src={organization.rectangular_promotion_banner}
                        alt="upload-organization"
                        className="image-uploaded"
                      />

                      <img src="/images/icons/icon-remove.png" className="btn-delete-image" alt="icon-remove" />

                    </div>
                    :
                    <FileUploader
                      handleChange={(e: any) => handleSelectImage(e, "rectangular_promotion_banner", 950, 300)}
                      name="file"
                      types={fileTypes}
                      classes="upload-image-group"
                    >
                      <PhotoIcon className="h-8 w-8 icon-upload-image" />

                      <Typography
                        onPointerEnterCapture="none" onPointerLeaveCapture="none"
                        placeholder="Typography" className="label-upload-image">
                        {t("drag-and-drop")}
                      </Typography>

                      <div className="btn-select-image">{t("choose-image")}</div>
                    </FileUploader>
                }


                {
                  error.rectangular_promotion_banner !== '' &&
                  <div className="error">{error.rectangular_promotion_banner}</div>
                }

              </div>

              <div className="form-row-item">
                <div className="form-label-group">
                  <span className="form-label">{t('square-promotion-banner')} (300 x 300)</span>

                  <LightTooltip title={t('tooltip-rectangular-promotion-banner')} className="w-[200px]" placement='top-start'>
                    <img src="/images/icons/tooltip.svg" alt="icon-tooltip" />
                  </LightTooltip>
                </div>

                {
                  (organization.square_promotion_banner && organization.square_promotion_banner !== '') ?
                    <div className="image-uploaded-group" onClick={() => handleRemoveImage("square_promotion_banner")}>
                      <img
                        src={organization.square_promotion_banner}
                        alt="upload-organization"
                        className="image-uploaded organization-logo"
                      />

                      <img src="/images/icons/icon-remove.png" className="btn-delete-image" alt="icon-remove" />
                    </div>
                    :
                    <FileUploader
                      handleChange={(e: any) => handleSelectImage(e, "square_promotion_banner", 300, 300)}
                      name="file"
                      types={fileTypes}
                      classes="upload-image-group"
                    >
                      <PhotoIcon className="h-8 w-8 icon-upload-image" />

                      <Typography
                        onPointerEnterCapture="none" onPointerLeaveCapture="none"
                        placeholder="Typography" className="label-upload-image">
                        {t("drag-and-drop")}
                      </Typography>

                      <div className="btn-select-image">{t('choose-image')}</div>
                    </FileUploader>
                }

                {
                  error.square_promotion_banner !== '' &&
                  <div className="error">{error.square_promotion_banner}</div>
                }

              </div>
            </div>

            <div className="form-row">
              <div className="form-row-item">
                <div className="form-label-group">
                  <span className="form-label">{t('organization-banner')} (1440x600)</span>

                  <LightTooltip title={t("tooltip-organization-banner")} className="w-[200px]" placement='top-start'>
                    <img src="/images/icons/tooltip.svg" alt="icon-tooltip" />
                  </LightTooltip>
                </div>

                {
                  organization.organization_banner !== '' ?
                    <div className="image-uploaded-group" onClick={() => handleRemoveImage("organization_banner")}>
                      <img
                        src={organization.organization_banner}
                        alt="upload-organization"
                        className="image-uploaded"
                      />

                      <img src="/images/icons/icon-remove.png" className="btn-delete-image" alt="icon-remove" />

                    </div>
                    :
                    <FileUploader
                      handleChange={(e: any) => handleSelectImage(e, "organization_banner", 1440, 600)}
                      name="file"
                      types={fileTypes}
                      classes="upload-image-group"
                    >
                      <PhotoIcon className="h-8 w-8 icon-upload-image" />

                      <Typography
                        onPointerEnterCapture="none" onPointerLeaveCapture="none"
                        placeholder="Typography" className="label-upload-image">
                        {t('drag-and-drop')}
                      </Typography>

                      <div className="btn-select-image">{t('choose-image')}</div>
                    </FileUploader>
                }

                {
                  error.organization_banner !== '' &&
                  <div className="error">{error.organization_banner}</div>
                }

              </div>

              {/* <div className="form-row-item">
                <div className="form-label-group">
                  <span className="form-label">Brand Logo</span>

                  <Tooltip content={t('tooltip-brand-logo')}>
                    <img src="/images/icons/tooltip.svg" alt="icon-tooltip" />
                  </Tooltip>
                </div>

                {
                  organization.brand_logo !== '' ?
                    <div className="image-uploaded-group" onClick={() => handleRemoveImage("brand_logo")}>
                      <img
                        src={organization.brand_logo}
                        alt="upload-organization"
                        className="image-uploaded"
                      />

                      <img src="/images/icons/icon-remove.png" className="btn-delete-image" alt="icon-remove" />

                    </div>
                    :
                    <FileUploader
                      handleChange={(e: any) => handleSelectImage(e, "brand_logo")}
                      name="file"
                      types={fileTypes}
                      classes="upload-image-group"
                    >
                      <PhotoIcon className="h-8 w-8 icon-upload-image" />

                      <Typography
                        onPointerEnterCapture="none" onPointerLeaveCapture="none"
                        placeholder="Typography" className="label-upload-image">
                        {t('drag-and-drop')}
                      </Typography>

                      <div className="btn-select-image">{t('choose-image')}</div>
                    </FileUploader>
                }

              </div> */}
            </div>

            {/* <div className="form-item">
              <div className="form-label-group">
                <span className="form-label">Brand Color</span>

                <Tooltip content={t('tooltip-brand-color')}>
                  <img src="/images/icons/tooltip.svg" alt="icon-tooltip" />
                </Tooltip>
              </div>

              <div className="brand-color-group">
                <div className="color-demo" style={{ backgroundColor: (organization.brand_color && organization.brand_color !== '') ? `#${organization.brand_color}` : 'red' }}></div>

                <span className="color-spell">#</span>

                <input
                  onChange={(e: any) => handleChangeValue(e.target.value, "brand_color")}
                  value={organization.brand_color}
                  type="text"
                  className="input-color"
                  autoComplete="off" />
              </div>
            </div> */}

          </div>
        </div>

        <div className="form">
          <div className="form-header">{t('edit')} {t('information')}</div>

          <div className="form-body">
            <div className="form-item">
              <div className="form-label-group">
                <span className="form-label">{t('organization-name')}</span>

                <LightTooltip title={t("tooltip-organization-name")} className="w-[200px]" placement='top-start'>
                  <img src="/images/icons/tooltip.svg" alt="icon-tooltip" />
                </LightTooltip>
              </div>

              <div className="introduction-group">
                <div className="introduction-language">Vietnam</div>

                <div className="organization-name">{organization.name.vi}</div>
              </div>

              <div className="introduction-group">
                <div className="introduction-language">English</div>

                <div className="organization-name">{organization.name.en}</div>
              </div>
            </div>

            <div className="form-item">
              <div className="form-label-group">
                <span className="form-label">{t('country')}</span>

                <img src="/images/icons/icon-require.svg" alt="icon-require" className="icon-require" />
              </div>

              <div className="select-country">
                <Select
                  onChange={(val) => handleChangeValue(val, 'country')}
                  placeholder="Select country"
                  value={organization.country}
                  onPointerEnterCapture="none" onPointerLeaveCapture="none"
                >
                  {
                    COUNTRY.map((item: any, index: number) => {
                      return (
                        <Option key={`${item.value}_${index}`} value={item.label}>{item.label}</Option>
                      )
                    })
                  }
                </Select>
              </div>

            </div>

            <div className="form-item">
              <div className="form-label-group">
                <span className="form-label">{t('organization-introduction')}</span>

                <LightTooltip title={t("tooltip-organization-introduction")} className="w-[200px]" placement='top-start'>
                  <img src="/images/icons/tooltip.svg" alt="icon-tooltip" />
                </LightTooltip>
              </div>

              <div className="introduction-group">
                <div className="introduction-language">Vietnam</div>

                <JoditEditor
                  ref={editor}
                  value={organization.introduction.vi}
                  config={config}
                  onBlur={newContent => { }}
                  onChange={async newContent => {
                    const position = newContent.indexOf('src="data:image');
                    let dataTemp = newContent;

                    if (position !== -1) {
                      dataTemp = dataTemp.slice(position + 5);

                      const positionBase64 = dataTemp.indexOf('width');

                      const base64 = dataTemp.slice(0, positionBase64 - 2);

                      const image = await dataURLtoFile(base64, `content_actiup`);

                      let imgLink = await handleUploadImagePost(image);

                      const replaceData = newContent.replace(base64, imgLink);

                      handleChangeValue(replaceData, "introduction", "vi")
                    } else {
                      handleChangeValue(newContent, "introduction", "vi")
                    }
                  }}
                />
              </div>

              <div className="introduction-group">
                <div className="introduction-language">English</div>

                <JoditEditor
                  ref={editor}
                  value={organization.introduction.en}
                  config={config}
                  onBlur={newContent => { }}
                  onChange={async newContent => {
                    const position = newContent.indexOf('src="data:image');
                    let dataTemp = newContent;

                    if (position !== -1) {
                      dataTemp = dataTemp.slice(position + 5);

                      const positionBase64 = dataTemp.indexOf('width');

                      const base64 = dataTemp.slice(0, positionBase64 - 2);

                      const image = await dataURLtoFile(base64, `content_actiup`);

                      let imgLink = await handleUploadImagePost(image);

                      const replaceData = newContent.replace(base64, imgLink);

                      handleChangeValue(replaceData, "introduction", "en")
                    } else {
                      handleChangeValue(newContent, "introduction", "en")
                    }
                  }}
                />
              </div>
            </div>
          </div>

        </div>

        <div className="form">
          <div className="form-header">
            <span className='mr-[10px]'>{t('seller-identification')}</span>

            <LightTooltip title={t('tooltip-seller-identification')} className="w-[200px]" placement='top-start'>
              <img src="/images/icons/tooltip.svg" alt="icon-tooltip" />
            </LightTooltip>
          </div>

          <div className="form-body">
            <div className="form-item">
              <div className="form-label-group">
                <span className="form-label">{t('company-license')}</span>
              </div>

              <div className='flex gap-x-[32px] flex-wrap gap-y-[12px]'>
                <FileUploader
                  handleChange={(e: any) => handleSelectLicense(e)}
                  name="file"
                  types={fileLicenseType}
                  multiple={true}
                  classes="upload-image-group-license"
                >
                  <Typography
                    onPointerEnterCapture="none" onPointerLeaveCapture="none"
                    placeholder="Typography" className="label-upload-image">
                    {t("drag-and-drop")}
                  </Typography>

                  <div className="btn-select-image">{t("choose-image")}</div>
                </FileUploader>

                {
                  isMobile &&
                  <div className='w-full flex gap-x-[12px] mt-[12px] text-14 text-[#868E95]'>
                    <span>{t('maximum-file-size')}</span>

                    <img src='/images/icons/icon-point.svg' alt='icon-point' />

                    <span>{t('support-file-format')}</span>
                  </div>
                }

                {
                  companyLicense?.length > 0 &&
                  <div className='grid grid-cols-1 gap-y-[8px]'>
                    {
                      companyLicense?.map((item: string, index: number) => {
                        return (
                          <div className='h-[28px] px-[12px] rounded-[100px] bg-[#F3E8FF] flex items-center justify-center gap-x-[8px]' key={`company_license_${index}`}>
                            <span className='text-14 text-[#011BB6]'>{getLicenseName(item)}</span>

                            <XMarkIcon className='size-[12px] text-[#011BB6] cursor-pointer' onClick={() => handleRemoveLicense(item)} />
                          </div>
                        )
                      })
                    }
                  </div>
                }
              </div>

              {
                !isMobile &&
                <div className='w-full flex gap-x-[12px] mt-[12px] text-14 text-[#868E95]'>
                  <span>{t('maximum-file-size')}</span>

                  <img src='/images/icons/icon-point.svg' alt='icon-point' />

                  <span>{t('support-file-format')}</span>
                </div>
              }

            </div>

            <div className="form-row">
              <div className="form-row-item">
                <div className="form-label-group">
                  <span className="form-label">{t('company-name')}</span>

                  <img src="/images/icons/icon-require.svg" alt="icon-require" className="icon-require" />
                </div>

                <input placeholder={t('company-name')} className='w-full h-[40px] rounded-[8px]' onChange={(e: any) => handleChangeValue(e.target.value, 'seller_company_name')} value={organization?.seller_company_name} />

                {
                  error.seller_company_name !== '' &&
                  <div className="error">{error.seller_company_name}</div>
                }

              </div>

              <div className="form-row-item">
                <div className="form-label-group">
                  <span className="form-label">{t('company-tax-id')}</span>
                </div>

                <input placeholder={t('company-tax-id')} className='w-full h-[40px] rounded-[8px]' onChange={(e: any) => handleChangeValue(e.target.value, 'seller_company_tax')} value={organization?.seller_company_tax} />

              </div>
            </div>

            <div className="form-row">
              <div className="form-row-item">
                <div className="form-label-group">
                  <span className="form-label">{t('company-certificate')}</span>
                </div>

                <input placeholder={t('company-certificate')} className='w-full h-[40px] rounded-[8px]' onChange={(e: any) => handleChangeValue(e.target.value, 'seller_business_certificate_number')} value={organization?.seller_business_certificate_number} />

              </div>

              <div className="form-row-item">
                <div className="form-label-group">
                  <span className="form-label">{t('company-issue-date')}</span>
                </div>

                <input placeholder='DD/MM/YYYY' type='date' className='w-full h-[40px] rounded-[8px]' onChange={(e: any) => handleChangeValue(e.target.value, 'seller_business_issue_date')} value={organization?.seller_business_issue_date} />

              </div>
            </div>

            <div className="form-row">
              <div className="form-row-item">
                <div className="form-label-group">
                  <span className="form-label">{t('company-place-of-issue')}</span>
                </div>

                <input placeholder={t('company-place-of-issue')} className='w-full h-[40px] rounded-[8px]' onChange={(e: any) => handleChangeValue(e.target.value, 'seller_place_of_issue')} value={organization?.seller_place_of_issue} />

              </div>

              <div className="form-row-item">
                <div className="form-label-group">
                  <span className="form-label">{t('company-business-address')}</span>
                </div>

                <input placeholder={t('company-business-address')} className='w-full h-[40px] rounded-[8px]' onChange={(e: any) => handleChangeValue(e.target.value, 'seller_business_address')} value={organization?.seller_business_address} />

              </div>
            </div>

            <div className="form-row">
              <div className="form-row-item">
                <div className="form-label-group">
                  <span className="form-label">Email</span>
                </div>

                <input placeholder='Email' className='w-full h-[40px] rounded-[8px]' onChange={(e: any) => handleChangeValue(e.target.value, 'seller_company_email')} value={organization?.seller_company_email} />

                {
                  error.seller_company_email !== '' &&
                  <div className="error">{error.seller_company_email}</div>
                }

              </div>

              <div className="form-row-item">
                <div className="form-label-group">
                  <span className="form-label">{t('phone-number')}</span>
                </div>

                <div className="phone-group">
                  {
                    phoneCode !== '' &&
                    <div className="select-phone-code">
                      <Select
                        value={phoneCode}
                        onChange={(val: any) => handleChangeValue(val, 'seller_company_phone_area_code')}
                        placeholder=""
                        onPointerEnterCapture="none" onPointerLeaveCapture="none"
                      >
                        {
                          COUNTRY.map((item: any, index: number) => {
                            return (
                              <Option key={`${item.value}_${index}`}
                                value={item.code}>{item.value} | +{item.code}</Option>
                            )
                          })
                        }
                      </Select>
                    </div>
                  }

                  <input placeholder={t('phone-number')} className='w-full h-[40px] border-none outline-none focus:outline-none focus:border-none' onChange={(e: any) => handleChangeValue(e.target.value, 'seller_company_phone')} value={organization?.seller_company_phone} />
                </div>

              </div>
            </div>
          </div>
        </div>

        <div className="btn-group">
          <Button
            onPointerEnterCapture="none" onPointerLeaveCapture="none"
            placeholder="Button Cancel" className="btn-cancel" onClick={() => navigate("/organization/info")}>{t('btn-cancel')}</Button>
          <Button
            onPointerEnterCapture="none" onPointerLeaveCapture="none"
            type="submit" placeholder="Button Save" className="btn-save" onClick={handleSubmit}>{t('btn-save')}</Button>
        </div>

        {
          message !== '' &&
          <Message
            message={message}
            messageType={messageType}
            setMessage={setMessage}
          />
        }

        {
          loading &&
          <Loader />
        }
      </div>
    )
  } else {
    return (
      <div className="w-full form-group"></div>
    )
  }

}

const EditOrganizationInformation = () => {
  const windowWidth = useWindowWidth();
  const { t } = useTranslation();

  if (windowWidth && windowWidth > 768) {
    return (
      <EditOrganizationInformationUI
        FormEditOrganization={FormEditOrganization}
        t={t}
      />
    )
  } else {
    return (
      <EditOrganizationInformationUIMobile
        FormEditOrganization={FormEditOrganization}
        t={t}
      />
    )
  }
};

export default EditOrganizationInformation;