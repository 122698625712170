import { useState, useEffect } from 'react';
import './Language.scss';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/20/solid';
import { useTranslation } from "react-i18next";
import {
  Dialog,
  DialogBody,
} from "@material-tailwind/react";
import { XMarkIcon } from '@heroicons/react/24/solid';

const languages = [
  {
    id: 'vi',
    name: `Tiếng Việt`,
    icon: '/images/icons/icon-vi.png'
  },
  {
    id: 'en',
    name: 'English',
    icon: '/images/icons/icon-en.png'
  },
];

const DialogLanguage = (props: any) => {
  const { open, setOpen, t, mobile, selectedLang, setSelectedLang, selectedCurrency, setSelectedCurrency } = props;

  const handleOpen = () => setOpen(!open);

  const handleChangeLanguage = () => {
    localStorage.setItem('lang', selectedLang);
    localStorage.setItem('wsCurrency', selectedCurrency);
    window.location.reload();
  }

  return (
    <Dialog open={open} handler={handleOpen} dismiss={{ outsidePress: false }} className={`focus:outline-none ${mobile ? '!w-[calc(100%-32px)] !max-w-[calc(100%-32px)]' : '!w-[620px] !max-w-[620px]'}`} onPointerEnterCapture="none" onPointerLeaveCapture="none" placeholder="dialog language">
      <DialogBody className="!font-montserrat" onPointerEnterCapture="none" onPointerLeaveCapture="none" placeholder="dialog language">
        <div className="w-full flex items-center justify-end">
          <XMarkIcon className="size-[20px] cursor-pointer text-[#3D3D3D]" strokeWidth={3} onClick={handleOpen} />
        </div>

        {
          !mobile ?
            <div className="w-full my-[16px]">
              <div className="flex gap-x-[24px]">
                <div className="w-[174px]">
                  <div className="text-16 text-[#3D3D3D] font-600 h-[40px] mb-[24px]">{t('languages')}</div>

                  <div className={`w-full h-[32px] px-[8px] flex items-center justify-between mb-[24px] cursor-pointer ${selectedLang !== 'en' && 'rounded-[12px] border border-[#011BB6]'}`} onClick={() => setSelectedLang('vi')}>
                    <div className="flex gap-x-[8px]">
                      <img loading="lazy" src="/images/icons/icon-vi.png" className="size-[22px] rounded-full" alt="actiup-icon-language" title="actiup-icon-language" />

                      <span className="text[#121212] text-14 font-500">Tiếng Việt</span>
                    </div>

                    {
                      selectedLang !== 'en' &&
                      <CheckIcon className="size-[16px] text-[#011BB6]" strokeWidth={2} />
                    }
                  </div>

                  <div className={`w-full h-[32px] px-[8px] flex items-center justify-between mb-[24px] cursor-pointer ${selectedLang === 'en' && 'rounded-[12px] border border-[#011BB6]'}`} onClick={() => setSelectedLang('en')}>
                    <div className="flex gap-x-[8px]">
                      <img loading="lazy" src="/images/icons/icon-en.png" className="size-[22px] rounded-full" alt="actiup-icon-language" title="actiup-icon-language" />

                      <span className="text[#121212] text-14 font-500">English</span>
                    </div>

                    {
                      selectedLang === 'en' &&
                      <CheckIcon className="size-[16px] text-[#011BB6]" strokeWidth={2} />
                    }
                  </div>
                </div>

                <div className="w-[1px] h-auto border border-main-border"></div>

                <div className="flex-1">
                  <div className="h-[40px] mb-[24px]">
                    <div className="text-16 text-[#3D3D3D] font-600">{t('currencies')}</div>
                    <div className="text-12 text[#121212] italic">{t('change_currencies_content')}</div>
                  </div>

                  <div className={`w-full h-[32px] px-[8px] flex items-center justify-between mb-[24px] cursor-pointer ${selectedCurrency !== 'usd' && 'rounded-[12px] border border-[#011BB6]'}`} onClick={() => setSelectedCurrency('vnd')}>
                    <div className="flex gap-x-[6px] text[#121212] text-14">
                      <span className="font-700">VND</span>

                      <span>-</span>

                      <span className="font-500">Vietnam Dong</span>
                    </div>

                    {
                      selectedCurrency !== 'usd' &&
                      <CheckIcon className="size-[16px] text-[#011BB6]" strokeWidth={2} />
                    }
                  </div>

                  <div className={`w-full h-[32px] px-[8px] flex items-center justify-between mb-[24px] cursor-pointer ${selectedCurrency === 'usd' && 'rounded-[12px] border border-[#011BB6]'}`} onClick={() => setSelectedCurrency('usd')}>
                    <div className="flex gap-x-[6px] text[#121212] text-14">
                      <span className="font-700">USD</span>

                      <span>-</span>

                      <span className="font-500">United States Dollar ($)</span>
                    </div>

                    {
                      selectedCurrency === 'usd' &&
                      <CheckIcon className="size-[16px] text-[#011BB6]" strokeWidth={2} />
                    }
                  </div>
                </div>
              </div>
            </div>
            :
            <div className="w-full my-[16px]">
              <div className="text-14 text-[#3D3D3D] font-700 mb-[16px]">{t('languages')}</div>

              <div className="w-full grid grid-cols-1 gap-y-[12px] mb-[24px]">
                <div className="w-full flex items-center justify-between">
                  <div className="flex gap-x-[8px]">
                    <img loading="lazy" src="/images/icons/icon-vi.png" className="size-[22px] rounded-full" alt="actiup-icon-language" title="actiup-icon-language" />

                    <span className="text[#121212] text-14 font-500">Tiếng Việt</span>
                  </div>

                  {
                    selectedLang !== 'en' &&
                    <CheckIcon className="size-[16px] text-[#011BB6]" strokeWidth={2} />
                  }
                </div>

                <div className="w-full h-[0.5px] border border-main-border"></div>

                <div className="w-full flex items-center justify-between">
                  <div className="flex gap-x-[8px]">
                    <img loading="lazy" src="/images/icons/icon-en.png" className="size-[22px] rounded-full" alt="actiup-icon-language" title="actiup-icon-language" />

                    <span className="text[#121212] text-14 font-500">English</span>
                  </div>

                  {
                    selectedLang === 'en' &&
                    <CheckIcon className="size-[16px] text-[#011BB6]" strokeWidth={2} />
                  }
                </div>
              </div>

              <div className="text-14 text-[#3D3D3D] font-700">{t('currencies')}</div>

              <div className="text-12 text-[#3D3D3D] italic">{t('change_currencies_content')}</div>

              <div className="w-full grid grid-cols-1 gap-y-[12px]">
                <div className={`w-full h-[32px] px-[8px] flex items-center justify-between cursor-pointer`} onClick={() => setSelectedCurrency('vnd')}>
                  <div className="flex gap-x-[6px] text[#121212] text-14">
                    <span className="font-700">VND</span>

                    <span>-</span>

                    <span className="font-500">Vietnam Dong</span>
                  </div>

                  {
                    selectedCurrency !== 'usd' &&
                    <CheckIcon className="size-[16px] text-[#011BB6]" strokeWidth={2} />
                  }
                </div>

                <div className="w-full h-[0.5px] border border-main-border"></div>

                <div className={`w-full h-[32px] px-[8px] flex items-center justify-between cursor-pointer`} onClick={() => setSelectedCurrency('usd')}>
                  <div className="flex gap-x-[6px] text[#121212] text-14">
                    <span className="font-700">USD</span>

                    <span>-</span>

                    <span className="font-500">United States Dollar ($)</span>
                  </div>

                  {
                    selectedCurrency === 'usd' &&
                    <CheckIcon className="size-[16px] text-[#011BB6]" strokeWidth={2} />
                  }
                </div>
              </div>

            </div>
        }

        <div className="w-full flex items-center justify-end">
          <div className="h-[40px] px-[14px] flex items-center justify-center rounded-[8px] bg-[#011BB6] text-white text-14 font-500 cursor-pointer" onClick={handleChangeLanguage}>{t('confirm')}</div>
        </div>
      </DialogBody>
    </Dialog>
  )

}

const Language = (props: any) => {
  const { mobile } = props;
  const [selected, setSelected] = useState(null as any);
  const { t, i18n = {} as any } = useTranslation();
  const [selectedLang, setSelectedLang] = useState('vi');
  const [selectedCurrency, setSelectedCurrency] = useState('vnd');
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const lang = localStorage.getItem('lang');

    if (lang) {
      lang === 'vi' ? setSelected(languages[0]) : setSelected(languages[1]);
      localStorage.setItem("lang", lang);
      setSelectedLang(lang)
      i18n.changeLanguage(lang);
    } else {
      setSelected(languages[0]);
      setSelectedLang('vi')
      localStorage.setItem("lang", "vi");
      i18n.changeLanguage("vi");
    }

    const currency = localStorage.getItem('wsCurrency');

    if (currency && currency !== '') {
      setSelectedCurrency(currency);
    } else {
      setSelectedCurrency('vnd');
    }

  }, [selected, i18n]);

  if (selected) {
    return (
      <div>
        <div className={`flex gap-x-[12px] mx-[32px] cursor-pointer p-[8px] items-center hover:rounded-[8px] hover:bg-[#F5F6F7]`} onClick={() => setOpen(true)}>
          <img src={selected.icon} className='size-[20px] rounded-full' />

          <span className='text-[#3D3D3D] text-14 font-bold uppercase'>{selectedCurrency}</span>

          <ChevronDownIcon className='size-[16px] text-[#3D3D3D]' strokeWidth={3} />
        </div>

        {
          open &&
          <DialogLanguage
            open={open}
            setOpen={setOpen}
            mobile={mobile}
            t={t}
            selectedLang={selectedLang}
            setSelectedLang={setSelectedLang}
            selectedCurrency={selectedCurrency}
            setSelectedCurrency={setSelectedCurrency} />
        }
      </div>
    )
  } else {
    return (
      <></>
    )
  }
}

export default Language;