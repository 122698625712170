import Breadcrumb from '@components/common/Breadcrumb';
import './information.scss';
import Mobile from '@layouts/mobile';
import { PencilIcon, PhotoIcon, NewspaperIcon, UsersIcon, FlagIcon } from '@heroicons/react/20/solid';
import Loader from '@components/common/Loader';
import Message from '@components/common/Message';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import moment from 'moment';

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

const OrganizationInformationUIMobile = (props: any) => {
  const { organizationInfo, loading, message, messageType, setMessage, t } = props;

  const breadcrumbItems = [
    {
      title: t('organization'),
      url: ''
    },
    {
      title: t('information'),
      url: ''
    }
  ];

  const getLicenseName = (url: string) => {
    const position = url.lastIndexOf('/');

    const name = url.slice(position + 1);

    return name;
  }


  return (
    <Mobile>
      <Breadcrumb
        breadcrumbItems={breadcrumbItems}
      />

      {
        (organizationInfo && Object.keys(organizationInfo).length > 0) &&
        <div id="organization-information-mobile">
          <div className="box-head">
            <div className="box-title">{t('organization-information')}</div>

            <a href="/organization/info/edit">
              <div className='btn-edit'>
                <PencilIcon className="h-5 w-5 icon-edit" />
              </div>
            </a>

          </div>

          <div className="box-body">
            <div className="box-sub-head">{t("images-and-colors")}</div>

            <div className="box-item">
              <div className="icon-group">
                <PhotoIcon className="h-4 w-4 icon" />
              </div>

              <div className="box-item-right">
                <div className="box-title-group">
                  <div className="box-item-title">{t('cover-banner')}</div>

                  <LightTooltip title={t('tooltip-cover-image')} className="w-[200px]" placement='top-start'>
                    <img src="/images/icons/tooltip.svg" alt="icon-tooltip" />
                  </LightTooltip>
                </div>

                {
                  organizationInfo.cover_banner && organizationInfo.cover_banner !== '' ?
                    <img src={organizationInfo.cover_banner}
                      className="w-[176px] h-[88px] object-contain object-center"
                      alt="icon-organization" />
                    :
                    <div className="image-default">
                      <PhotoIcon className="w-5 h-5 icon-image-default" />
                    </div>
                }
              </div>
            </div>

            <div className="box-item">
              <div className="icon-group">
                <PhotoIcon className="h-4 w-4 icon" />
              </div>

              <div className="box-item-right">
                <div className="box-title-group">
                  <div className="box-item-title">{t('rectangular-promotion-banner')}</div>

                  <LightTooltip title={t('tooltip-rectangular-promotion-banner')} className="w-[200px]" placement='top-start'>
                    <img src="/images/icons/tooltip.svg" alt="icon-tooltip" />
                  </LightTooltip>
                </div>

                {
                  organizationInfo.rectangular_promotion_banner && organizationInfo.rectangular_promotion_banner !== '' ?
                    <img src={organizationInfo.rectangular_promotion_banner}
                      className="w-[176px] h-[88px] object-contain object-center"
                      alt="icon-organization" />
                    :
                    <div className="w-[176px] h-[88px] flex items-center justify-center rounded-[8px] bg-[#f6f5fe]">
                      <PhotoIcon className="w-5 h-5 icon-image-default" />
                    </div>
                }
              </div>
            </div>

            <div className="box-item">
              <div className="icon-group">
                <PhotoIcon className="h-4 w-4 icon" />
              </div>

              <div className="box-item-right">
                <div className="box-title-group">
                  <div className="box-item-title">{t('square-promotion-banner')}</div>

                  <LightTooltip title={t('tooltip-rectangular-promotion-banner')} className="w-[200px]" placement='top-start'>
                    <img src="/images/icons/tooltip.svg" alt="icon-tooltip" />
                  </LightTooltip>
                </div>

                {
                  organizationInfo.square_promotion_banner && organizationInfo.square_promotion_banner !== '' ?
                    <img src={organizationInfo.square_promotion_banner}
                      className="w-[176px] h-[88px] object-contain object-center"
                      alt="icon-organization" />
                    :
                    <div className="w-[100px] h-[100px] flex items-center justify-center rounded-[8px] bg-[#f6f5fe]">
                      <PhotoIcon className="w-5 h-5 icon-image-default" />
                    </div>
                }
              </div>
            </div>

            <div className="box-item">
              <div className="icon-group">
                <PhotoIcon className="h-4 w-4 icon" />
              </div>

              <div className="box-item-right">
                <div className="box-title-group">
                  <div className="box-item-title">{t('organization-logo')}</div>

                  <LightTooltip title={t('tooltip-organization-logo')} className="w-[200px]" placement='top-start'>
                    <img src="/images/icons/tooltip.svg" alt="icon-tooltip" />
                  </LightTooltip>
                </div>

                {
                  organizationInfo.organization_logo && organizationInfo.organization_logo !== '' ?
                    <img src={organizationInfo.organization_logo}
                      className="w-[176px] h-[88px] object-contain object-center"
                      alt="icon-organization" />
                    :
                    <div className="image-default organization-logo">
                      <PhotoIcon className="w-5 h-5 icon-image-default" />
                    </div>
                }
              </div>
            </div>

            <div className="box-item">
              <div className="icon-group">
                <PhotoIcon className="h-4 w-4 icon" />
              </div>

              <div className="box-item-right">
                <div className="box-title-group">
                  <div className="box-item-title">{t('organization-banner')}</div>

                  <LightTooltip title={t('tooltip-organization-banner')} className="w-[200px]" placement='top-start'>
                    <img src="/images/icons/tooltip.svg" alt="icon-tooltip" />
                  </LightTooltip>
                </div>

                {
                  organizationInfo.organization_banner && organizationInfo.organization_banner !== '' ?
                    <img src={organizationInfo.organization_banner}
                      className="w-[176px] h-[88px] object-contain object-center"
                      alt="icon-organization" />
                    :
                    <div className="image-default">
                      <PhotoIcon className="w-5 h-5 icon-image-default" />
                    </div>
                }
              </div>
            </div>

            {/* <div className="box-item">
              <div className="icon-group">
                <PhotoIcon className="h-4 w-4 icon" />
              </div>

              <div className="box-item-right">
                <div className="box-title-group">
                  <div className="box-item-title">Brand Logo</div>

                  <Tooltip content={t("tooltip-brand-logo")}>
                    <img src="/images/icons/tooltip.svg" alt="icon-tooltip" />
                  </Tooltip>
                </div>

                {
                  organizationInfo.brand_logo && organizationInfo.brand_logo !== '' ?
                    <img src={organizationInfo.brand_logo}
                      className="w-[176px] h-[88px] object-contain object-center"
                      alt="icon-organization" />
                    :
                    <div className="image-default">
                      <PhotoIcon className="w-5 h-5 icon-image-default" />
                    </div>
                }
              </div>
            </div>

            <div className="box-item">
              <div className="icon-group">
                <SwatchIcon className="h-4 w-4 icon" />
              </div>

              <div className="box-item-right">
                <div className="box-title-group">
                  <div className="box-item-title">Brand color</div>

                  <Tooltip content={t("tooltip-brand-color")}>
                    <img src="/images/icons/tooltip.svg" alt="icon-tooltip" />
                  </Tooltip>
                </div>

                <div className="box-color">
                  <div className="color-demo" style={{ backgroundColor: (organizationInfo.brand_color && organizationInfo.brand_color !== '') ? `#${organizationInfo.brand_color}` : '#011BB6' }}></div>

                  <div className="color-name"><span>#</span>{organizationInfo.brand_color}</div>
                </div>
              </div>
            </div> */}
          </div>

          <div className="box-body">
            <div className="box-sub-head">{t("information")}</div>

            <div className="box-item">
              <div className="icon-group">
                <UsersIcon className="h-4 w-4 icon" />
              </div>

              <div className="box-item-right">
                <div className="box-title-group">
                  <div className="box-item-title">{t('organization-name')}</div>

                  <LightTooltip title={t('tooltip-organization-name')} className="w-[200px]" placement='top-start'>
                    <img src="/images/icons/tooltip.svg" alt="icon-tooltip" />
                  </LightTooltip>
                </div>

                <div className="box-information">
                  <div className="box-chip">Vietnam</div>

                  <div className="box-content">
                    {organizationInfo.name.vi}
                  </div>
                </div>

                <div className="box-information">
                  <div className="box-chip">English</div>

                  <div className="box-content">
                    {organizationInfo.name.en}
                  </div>
                </div>
              </div>
            </div>

            <div className="box-item">
              <div className="icon-group">
                <FlagIcon className="h-4 w-4 icon" />
              </div>

              <div className="box-item-right">
                <div className="box-title-group">
                  <div className="box-item-title">{t('country')}</div>
                </div>

                <div className="box-content">
                  {organizationInfo.country}
                </div>
              </div>
            </div>

            <div className="box-item">
              <div className="icon-group">
                <NewspaperIcon className="h-4 w-4 icon" />
              </div>

              <div className="box-item-right">
                <div className="box-title-group">
                  <div className="box-item-title">{t('organization-introduction')}</div>

                  <LightTooltip title={t("tooltip-organization-introduction")} className="w-[200px]" placement='top-start'>
                    <img src="/images/icons/tooltip.svg" alt="icon-tooltip" />
                  </LightTooltip>
                </div>


                <div className="box-information">
                  <div className="box-chip">Vietnam</div>

                  <div className="box-content" dangerouslySetInnerHTML={{
                    __html: organizationInfo.introduction.vi,
                  }}>
                  </div>
                </div>

                <div className="box-information">
                  <div className="box-chip">English</div>

                  <div className="box-content" dangerouslySetInnerHTML={{
                    __html: organizationInfo.introduction.en,
                  }}>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="box-body">
            <div className="box-sub-head">
              <span className='mr-[10px]'>{t("seller-identification")}</span>

              <LightTooltip title={t('tooltip-organization-name')} className="w-[200px]" placement='top-start'>
                <img src="/images/icons/tooltip.svg" alt="icon-tooltip" />
              </LightTooltip>
            </div>

            <div className="box-item">
              <div className="icon-group">
                <img src='/images/icons/icon-company-name.svg' alt='icon-company-name' />
              </div>

              <div className="box-item-right">
                <div className="box-title-group">
                  <div className="box-item-title">{t('company-name')}</div>
                </div>

                <div className='single-value'>{organizationInfo.seller_company_name}</div>
              </div>
            </div>

            <div className="box-item">
              <div className="icon-group">
                <img src='/images/icons/icon-company-tax.svg' alt='icon-company-tax-id' />
              </div>

              <div className="box-item-right">
                <div className="box-title-group">
                  <div className="box-item-title">{t('company-tax-id')}</div>
                </div>

                <div className='single-value'>{organizationInfo.seller_company_tax}</div>
              </div>
            </div>

            <div className="box-item">
              <div className="icon-group">
                <img src='/images/icons/icon-company-license.svg' alt='icon-company-license' />
              </div>

              <div className="box-item-right">
                <div className="box-title-group">
                  <div className="box-item-title">{t('company-license')}</div>
                </div>

                {
                  (organizationInfo.seller_business_license && organizationInfo.seller_business_license.length > 0) &&
                  <div className='grid grid-cols-1 gap-y-[8px]'>
                    {
                      organizationInfo.seller_business_license.map((license: string, index: number) => {
                        return (
                          <a href={license} target='_blank' className='text-14 text-[#011BB6] underline' key={`license_number_${index}`}>{getLicenseName(license)}</a>
                        )
                      })
                    }
                  </div>
                }
                {/* <div className='single-value'>{organizationInfo.seller_business_license}</div> */}
              </div>
            </div>

            <div className="box-item">
              <div className="icon-group">
                <img src='/images/icons/icon-company-certificate.svg' alt='icon-company-certificate' />
              </div>

              <div className="box-item-right">
                <div className="box-title-group">
                  <div className="box-item-title">{t('company-certificate')}</div>
                </div>

                <div className='single-value'>{organizationInfo.seller_business_certificate_number}</div>
              </div>
            </div>

            <div className="box-item">
              <div className="icon-group">
                <img src='/images/icons/icon-company-issue-date.svg' alt='icon-company-issue-date' />
              </div>

              <div className="box-item-right">
                <div className="box-title-group">
                  <div className="box-item-title">{t('company-issue-date')}</div>
                </div>

                <div className='single-value'>{moment(organizationInfo.seller_business_issue_date).format('DD/MM/YYYY')}</div>
              </div>
            </div>

            <div className="box-item">
              <div className="icon-group">
                <img src='/images/icons/icon-company-name.svg' alt='icon-company-name' />
              </div>

              <div className="box-item-right">
                <div className="box-title-group">
                  <div className="box-item-title">{t('company-place-of-issue')}</div>
                </div>

                <div className='single-value'>{organizationInfo.seller_place_of_issue}</div>
              </div>
            </div>

            <div className="box-item">
              <div className="icon-group">
                <img src='/images/icons/icon-company-name.svg' alt='icon-company-name' />
              </div>

              <div className="box-item-right">
                <div className="box-title-group">
                  <div className="box-item-title">{t('company-business-address')}</div>
                </div>

                <div className='single-value'>{organizationInfo.seller_business_address}</div>
              </div>
            </div>

            <div className="box-item">
              <div className="icon-group">
                <img src='/images/icons/icon-company-email.svg' alt='icon-company-email' />
              </div>

              <div className="box-item-right">
                <div className="box-title-group">
                  <div className="box-item-title">Email</div>
                </div>

                <div className='single-value'>{organizationInfo.seller_company_email}</div>
              </div>
            </div>

            <div className="box-item">
              <div className="icon-group">
                <img src='/images/icons/icon-company-phone.svg' alt='icon-company-phone' />
              </div>

              <div className="box-item-right">
                <div className="box-title-group">
                  <div className="box-item-title">{t('phone')}</div>
                </div>

                <div className='single-value'>(+{organizationInfo.seller_company_phone_area_code}) {organizationInfo.seller_company_phone}</div>
              </div>
            </div>

          </div>
        </div>
      }


      {
        loading &&
        <Loader />
      }

      {
        message !== '' &&
        <Message
          message={message}
          messageType={messageType}
          setMessage={setMessage}
        />
      }
    </Mobile>
  )
};

export default OrganizationInformationUIMobile;