import Breadcrumb from '@components/common/Breadcrumb';
import './EditInformation.scss';
import Mobile from '@layouts/mobile';

const EditOrganizationInformationUIMobile = (props: any) => {
  const { FormEditOrganization, t } = props;

  const breadcrumbItems = [
    {
      title: t('organization'),
      url: ''
    },
    {
      title: t('information'),
      url: '/organization/information'
    },
    {
      title: t('edit-information'),
      url: ''
    }
  ];

  return (
    <Mobile>
      <div id="edit-organization-ui-mobile">
        <Breadcrumb
          breadcrumbItems={breadcrumbItems}
        />

        <div className="form-group">
          <FormEditOrganization
            t={t}
            isMobile={true}
          />
        </div>
      </div>
    </Mobile>
  )
};

export default EditOrganizationInformationUIMobile;